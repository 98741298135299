import React, { useState, useEffect, useCallback } from 'react';
import BlogCard from './BlogCard';
import { Container, Row } from 'react-bootstrap';
import LatestArticles from './LatestArticles';
import BASE_URLS from '../../baseUrl';

function Blog() {
  const mainApiUrl = BASE_URLS.main;
  const [blog, setBlog] = useState();

  const fetchBlog = useCallback(async () => {
    try {
      const response = await fetch(`${mainApiUrl}/get_blog.php`);
      if (response.ok) {
        const data = await response.json();
        setBlog(data);
      } else {
        throw new Error('Failed to fetch blog');
      }
    } catch (error) {
      console.error(error);
    }
  }, [mainApiUrl]);

  useEffect(() => {
    fetchBlog();
  }, [fetchBlog]);

  // Sample blog data
  const backgroundStyle = {
    backgroundImage:
      'url("https://images.ctfassets.net/dsbipkqphva2/3r8fPF98EojAknGaLkeOPM/a0bd8ebcdb2d44a60a6d3b52656928ec/article_hero.jpg?fm=webp&w=1800")',
  };

  return (
    <div className="body-content">
      <section className="blog-banner" style={backgroundStyle}>
        <Container>
          <Row>
            <div className="col-12">
              <div className="banner-title">
                <h2 className="text-white fw-bold">Discover travel guides and stories</h2>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <BlogCard blogCard={blog?.blog_popular} />
      <LatestArticles LatestArticles={blog?.blog_recent} />
    </div>
  );
}

export default Blog;
