
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from 'react-router-dom';
import BASE_URLS from '../baseUrl';
import { useContact } from "../Contexts/ContactContext";

function Footer() {
    const currentYear = new Date().getFullYear();
    const webUrl = BASE_URLS.webUrl;

    const { addr, loading } = useContact();

    if (loading) {
        return (
            <div id="preloader"></div>
          )
    }

    return (
        <footer className="footer-style-1">
            <Container>
                <Row className="gy-3 copyright">
                    <Col md={6}>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item"> <Link to={`${webUrl}about-us`}>About Us</Link></li>
                            <li className="list-inline-item"> <Link to={`${webUrl}contact-us`}>Contact Us</Link></li>
                            <li className="list-inline-item"> <Link to={`${webUrl}blog`}>Blog</Link></li>
                            <li className="list-inline-item"> <Link to={`${webUrl}privacy-policy`}>Privacy Policy</Link></li>
                            <li className="list-inline-item"> <Link to={`${webUrl}cookie-policy`}>Cookie Policy</Link></li>
                            <li className="list-inline-item"> <Link to={`${webUrl}terms-of-use`}>Terms of Use</Link></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <p className="mb-0">©{currentYear} Copyright Rathin Tourism LLC. All Rights Reserved</p>
                    </Col>
                    <Col md={2} className="footer-links text-right">
                        <div className="social-links d-flex justify-content-center">
                            <Link to={addr.twitter_link} target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Instagram">
                                    <i className="bi bi-twitter"></i>
                                </button>
                            </Link>
                            <Link to={addr.fb_link} target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Facebook">
                                    <i className="bi bi-facebook"></i>
                                </button>
                            </Link>
                            <Link to={addr.insta_link} target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Twitter">
                                    <i className="bi bi-instagram"></i>
                                </button>
                            </Link>
                            <Link to={addr.li_link} target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Linkedin">
                                    <i className="bi bi-linkedin"></i>
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            {}
        </footer>
    )
}
export default Footer;