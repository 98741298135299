import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import confirmation from "../../Images/confirmation.svg";
import { useLocation } from "react-router-dom";
import { useCurr } from "../../Contexts/currContext";
import { PaymentFunctions } from "./PaymentFunctions";
import Loader from "../../Loader";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export const BookingConfirmation = () => {
  const webUrl = BASE_URLS.webUrl;
  const payUrl = BASE_URLS.payUrl;
  const location = useLocation();
  const state = location.state;
  const bookingDetails = state ? state.orderDetails : null;
  const customerDetails = state ? state.customerDetails : null;
  const dicount_percent = state ? state.dicount_percent : null;
  const selectedTickets = state ? state.selectedTickets : null;
  const token_id = state ? state.token_id : null;
  const promoCode = state ? state.promoCode : "";
  const { selectedCurrency, currConvert } = useCurr();
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handlePayment = async () => {
    if (!isLoggedIn) {
      // Handle the case when the user is not logged in, e.g., show a login modal or redirect to the login page.
      // You can use state management (like Redux) or a global context to manage user authentication.
      window.location.href = `${webUrl}/my-account`;
      return;
    }
    // return;
    const usr_id = localStorage.getItem("setAuthUser");
    const paymentFunc = PaymentFunctions();

    // return;
    setLoading(true);
    try {
      const orderDetails = {
        order_no: "ORDER1",
        usr_id: usr_id,
        products_id: bookingDetails.product_id_num,
        actual_price: bookingDetails.actual_amount,
        total_price: bookingDetails.totalAmount,
        payment_amount: bookingDetails.totalAmount,
        first_name: customerDetails.first_name,
        last_name: customerDetails.last_name,
        email_id: customerDetails.email,
        mobile_no: customerDetails.mobile_number,
        promo_code: promoCode,
        promo_percentage: dicount_percent,
        special_request: customerDetails.request || "",
        booking_date: bookingDetails.booking_date,
        booking_time: bookingDetails.booking_time,
        created_by: usr_id,
        selectedTickets: bookingDetails.selectedTickets,
      };


      const orderInsert = await paymentFunc.insertOrder(orderDetails);
      // return;

      if (orderInsert.status === true) {
        const orderPayload = {
          amount: parseInt(bookingDetails.totalAmount),
          currency: "AED",
          customer_initiated: true,
          threeDSecure: true,
          save_card: false,
          customer: {
            first_name: customerDetails.first_name,
            last_name: customerDetails.last_name,
            email: customerDetails.email,
            phone: {
              number: customerDetails.mobile_number,
            },
          },
          source: {
            id: token_id,
          },
          post: {
            url: payUrl+"webadmin/api/webhook_response.php",
          },
          redirect: {
            url:
            payUrl+"webadmin/api/redirect_url?order_id=" +
              orderInsert.order_id,
          },
        };

        const OrderDetails = await paymentFunc.tapChargeApi(orderPayload);
        if (OrderDetails && OrderDetails.transaction.url) {
          // Redirect the user to the specified URL
          // setLoading(false);
          window.location.href = OrderDetails.transaction.url;
        } else {
          setLoading(false);
          console.error("No redirect URL found in the response.");
          navigate(`${webUrl}` + "booking-failure");
          // Handle the case where no redirect URL is present in the response
        }
      }
    } catch (error) {
      console.error("Error in handlePayment:", error);
      navigate(`${webUrl}` + "booking-failure");
    }
  };

  return (
    <div className="body-content">
      <Helmet>
        <title>Make Payments | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Make Payments</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className="payments">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="booking success">
                <div className="booking-header">
                  <div className="bheader">
                    <img
                      src={confirmation}
                      width="250px"
                      className="img-fluid pt-3 pb-3"
                      alt="Order Summary"
                      loading="lazy"
                      height="auto"
                    />
                  </div>
                </div>
                <h3 className="font-weight-bold mt-3">
                  Here's the summary of your booking
                </h3>
              </div>
              <div className="mt-4">
                <div className="table table-striped">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Guest name</th>
                        <td>
                          {customerDetails.first_name}{" "}
                          {customerDetails.last_name}
                        </td>
                      </tr>
                      <tr>
                        <th>Guest email</th>
                        <td>{customerDetails.email}</td>
                      </tr>
                      <tr>
                        <th>Guest mobile</th>
                        <td>{customerDetails.mobile_number}</td>
                      </tr>
                      <tr>
                        <th>Guest Special Request</th>
                        <td>{customerDetails.request}</td>
                      </tr>
                      <tr>
                        <th>Product Name</th>
                        <td>{bookingDetails.product_id}</td>
                      </tr>
                      <tr>
                        <th>Package Name</th>
                        <td>{bookingDetails.package_name} </td>
                      </tr>
                      <tr>
                        <th>Addon</th>
                        <td>Addon name</td>
                      </tr>
                      <tr>
                        <th>Date</th>
                        <td>{bookingDetails.selectedDate}</td>
                      </tr>
                      <tr>
                        <th>Total Amount</th>
                        <td>
                          {selectedCurrency}{" "}
                          {currConvert(bookingDetails.totalAmount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <center className="mt-4">
                  {loading ? (
                    <Loader /> // Display loader when loading is true
                  ) : (
                    <>
                      <Button variant="primary" onClick={handlePayment}>
                        Confirm Booking
                      </Button>
                      <Link to={webUrl}>
                        <Button variant="outline-primary">
                          Cancel Booking
                        </Button>
                      </Link>
                    </>
                  )}
                </center>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
