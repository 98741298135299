import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function BlogCard({ blogCard }) {

  return (
    <section>
      <Container>
        <div className="section-header text-start">
          <p>Popular articles</p>
        </div>
        <div className="gy-4 row">
          {blogCard?.slice(0, 6).map((blog) => (
            <div key={blog.blog_id} className="col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card">
                <Link to={`/blog/${blog.blog_slug}`}>
                  <img
                    src={blog.blog_image}
                    className="img-fluid rounded-3 mb-3"
                    alt={blog.blog_title}
                    title={blog.blog_title}
                  />
                  <h5 className='fw-bold'>{blog.blog_title}</h5>
                  <div className="blog-footer">
                    <span className="text-muted">{blog.blog_dtm}</span>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default BlogCard;
