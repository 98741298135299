import React, { useState } from 'react';
import { SocialModal } from '../Activity/socialModal';
import { Helmet } from "react-helmet-async";

function BlogContent({blogDetails}) {

    const [showSocialModal, setShowSocialModal] = useState(false);
    const openSocialModal = () => {
        setShowSocialModal(true);
    };
    const closeSocialModal = () => {
        setShowSocialModal(false);
    };
    return (
        <>
            <div className='blog_main_content'>
                <Helmet>
                    <meta name="description" content={blogDetails?.seo_desc} />
                    <title>{blogDetails?.blog_title}</title>
                </Helmet>
                <div className='mb-3 blog_title'>
                    <h1 className='fw-bold'>{blogDetails?.blog_title}</h1>
                    <div className='blog_share_modal mt-2'>
                        <p onClick={openSocialModal}><i className="bi bi-share"></i> Share</p>
                    </div>
                </div>

                <div className='blog_main_author mb-3'>
                    <img className="rounded-circle img-fluid me-3"
                        src={blogDetails?.created_by_img}
                        alt={blogDetails?.blog_title}
                        title={blogDetails?.blog_title} />
                    <div className='author_meta'>
                        <h6 className='author_name fw-bold'>{blogDetails?.created_by}</h6>
                        <p className='author_post_time text-muted mb-0 small'><span>Last updated:</span> {blogDetails?.blog_dtm}</p>
                    </div>
                </div>
                <img src={blogDetails?.blog_image} className='img-fluid rounded-3 mb-3'/>
                <div className='blog_desc mb-3'>
                  <div className="overflow-hidden" dangerouslySetInnerHTML={{ __html: blogDetails?.blog_content }}></div>

                </div>
                <div className='blog_share'>

                </div>
            </div>
            <SocialModal showSocial={showSocialModal} onHide={closeSocialModal} />
        </>
    );
}

export default BlogContent;
