import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./App.css";
import "./Responsive.css";
import AppBar from "./Components/AppBar";
import Footer from "./Components/Footer";
import Home from "./Components/Home/Home";
import AllDestinations from "./Components/AllDestinations";
import ThingstoDo from "./Components/ThingsToDo/ThingToDo";
import Loader from "./Loader";
import CheckOut from "./Components/CheckOut/CheckOut";
import { Activity } from "./Components/Activity/Activity";
import { ActivityList } from "./Components/ActivityList/ActivityList";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import CookiesPolicy from "./Components/CookiesPolicy";
import TermsOfUse from "./Components/TermsOfUse";
import { About } from "./Components/About/About";
import { HelpCenter } from "./Components/HelpCenter/HelpCenter";
import { Articles } from "./Components/HelpCenter/Article";
import { NotFound } from "./Components/NotFound";
import { FaqForm } from "./Components/HelpCenter/FaqForm";
import { ContactUs } from "./Components/Contact/ContactUs";
import { ForgotPassword } from "./Components/ForgotPassword";
import { Wishlist } from "./Components/UserProfile/Wishlist";
import SettingsTab from "./Components/UserProfile/SettingsTab";
import ProfileTab from "./Components/UserProfile/ProfileTab";
import BookingTab from "./Components/UserProfile/BookingTab";
import ReviewTab from "./Components/UserProfile/ReviewTab";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./Contexts/AuthContext";
import BASE_URLS from "./baseUrl";
import ResetPassword from "./Components/resetPassword";
import { BookingConfirmation } from "./Components/CheckOut/BookingConfirmation";
import { BookingSuccess } from "./Components/CheckOut/BookingSuccess";
import { BookingFailure } from "./Components/CheckOut/BookingFailure";
import { PaymentRedirect } from "./Components/CheckOut/PaymentRedirect";
import { OrderDetails } from "./Components/UserProfile/OrderDetails";
import Blog from "./Components/Blog/Blog";
import BlogDetails from "./Components/BlogDetails/BlogDetails";

function App({ user }) {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Add this line
  const location = useLocation();
  const webUrl = BASE_URLS.webUrl;

  const { isLoggedIn, isLoggedInWithGmail, handleGoogleLogout } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 100);
  }, [location.pathname]);

  return (
    <>
      <AppBar
        user={user}
        isLoggedInWithGmail={isLoggedInWithGmail}
        isLoggedIn={isLoggedIn}
        navigate={navigate}
        handleGoogleLogout={handleGoogleLogout}
      />
      {loading ? (
        <Loader />
      ) : (
        <Routes>
          <Route path={webUrl} element={<Home />} />
          <Route path={`${webUrl}destinations`} element={<AllDestinations />} />
          <Route
            path={`${webUrl}things-to-do/:city`}
            element={<ThingstoDo />}
          />
          <Route path={`${webUrl}activity/:id`} element={<Activity />} />
          <Route path={`${webUrl}activity-list`} element={<ActivityList />} />
          <Route
            path={`${webUrl}checkout`}
            isLoggedIn={isLoggedIn}
            element={<CheckOut />}
          />
          <Route path={`${webUrl}privacy-policy`} element={<PrivacyPolicy />} />
          <Route path={`${webUrl}cookie-policy`} element={<CookiesPolicy />} />
          <Route path={`${webUrl}terms-of-use`} element={<TermsOfUse />} />
          <Route path={`${webUrl}about-us`} element={<About />} />
          <Route path={`${webUrl}contact-us`} element={<ContactUs />} />
          <Route path={`${webUrl}help-center`} element={<HelpCenter />} />
          <Route path={`${webUrl}articles/:cat_id`} element={<Articles />} />
          <Route path={`${webUrl}faq-form`} element={<FaqForm />} />
          <Route path={`${webUrl}forgot-password`}  element={
              isLoggedIn ? (
                <Navigate to={webUrl} />
              ) : (
                <ForgotPassword />
              )
            }
          />
          <Route path={`${webUrl}reset-password/:token`}  element={
              isLoggedIn ? (
                <Navigate to={webUrl} />
              ) : (
                <ResetPassword />
              )
            }
          />
          <Route
            path={`${webUrl}order-summary`}
            element={
              isLoggedIn ? <BookingConfirmation /> : <Navigate to={webUrl} />
            }
          />
          <Route
            path={`${webUrl}booking-success`}
            element={<BookingSuccess />}
          />
          <Route
            path={`${webUrl}booking-failure`}
            element={<BookingFailure />}
          />
          <Route path={`${webUrl}redirect_url`} element={<PaymentRedirect />} />

          <Route
            path={`${webUrl}wishlist`}
            element={isLoggedIn ? <Wishlist /> : <Navigate to={webUrl} />}
          />
          <Route
            path={`${webUrl}account/bookings`}
            element={isLoggedIn ? <BookingTab /> : <Navigate to={webUrl} />}
          />
          <Route
            path={`${webUrl}account/reviews`}
            element={isLoggedIn ? <ReviewTab /> : <Navigate to={webUrl} />}
          />
          <Route
            path={`${webUrl}account/profile`}
            element={isLoggedIn ? <ProfileTab /> : <Navigate to={webUrl} />}
          />
          <Route
            path={`${webUrl}account/settings`}
            element={isLoggedIn ? <SettingsTab /> : <Navigate to={webUrl} />}
          />
          <Route
            path={`${webUrl}account/order-details/:orderId`}
            element={isLoggedIn ? <OrderDetails /> : <Navigate to={webUrl} />}
          />  
          <Route
            path={`${webUrl}blog`}
            element={<Blog />}
          />
          <Route
            path={`${webUrl}blog/:blogSlug`}
            element={<BlogDetails />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
      <Footer />
    </>
  );
}

export default App;
