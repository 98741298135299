import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function LatestArticles({ LatestArticles }) {
  const [visibleCount, setVisibleCount] = useState(10); // Initial number of articles to display

  // Ensure LatestArticles is an array
  const articles = Array.isArray(LatestArticles) ? LatestArticles : [];

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10); // Increment the count by 4 on each click
  };

  const articlesToShow = articles.slice(0, visibleCount); // Get the subset of articles to display

  return (
    <>
      <section>
        <Container>
          <div className="section-header text-start">
            <p>Latest articles</p>
          </div>
          <div className="gy-4 row mb-3">
            {articlesToShow.map((article) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                key={article.blog_id}
              >
                <div className="articles_card pb-4">
                  <Link
                    className="d-flex"
                    to={`/blog/${article.blog_slug}`}
                    state={{ blogData: article }}
                  >
                    <div className="article_img">
                      <img
                        src={article.blog_image}
                        className="img-fluid rounded-3"
                        alt={article.blog_title}
                        title={article.blog_title}
                      />
                    </div>
                    <div className="article_body">
                      <div className="article_bod_desc">
                        <h5 className="fw-bold">{article.blog_title}</h5>
                        <div className="article_time">
                          <p className="text-muted mb-0 small">{article.blog_dtm}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          {visibleCount < articles.length && ( // Show button only if there are more articles
            <div className="row">
              <div className="col-lg-12 text-center">
                <Button onClick={loadMore}>
                  See all activities
                </Button>
              </div>
            </div>
          )}
        </Container>
      </section>
    </>
  );
}

export default LatestArticles;
