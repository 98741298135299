import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BASE_URLS from '../../baseUrl';
import coupon from '../../Images/offer.svg';
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from MUI
import { Col, Row } from "react-bootstrap";

function Coupon() {
    const mainApiUrl = BASE_URLS.main;
    const webUrl = BASE_URLS.webUrl;
    const [copied, setCopied] = useState(false);
    const [couponDetails, setCouponDetails] = useState(null);
    const [loading, setLoading] = useState(true); // New state for loading

    useEffect(() => {
        fetchCouponDetails();
    }, []);

    const fetchCouponDetails = async () => {
        try {
            const response = await fetch(
                `${mainApiUrl}/get_coupon.php`
              );
            if (response.ok) {
                const data = await response.json();
                setCouponDetails(data);
            } else {
                throw new Error('Failed to fetch coupon details');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false when data fetching is complete
        }
    };

    // If loading, return skeleton
    if (loading) {
        return (
            <Row className='mt-3'>
                <Col>
                    <div className="coupon-card mt-3">
                        <div className="d-flex gap-2">
                            <Skeleton animation="wave" variant="rectangular" height={150} width="100%" />
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    // If couponDetails is null or incomplete, hide the component
    if (!couponDetails || !couponDetails.coup_code || !couponDetails.coup_percentage) {
        return null;
    }

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    return (
        <Row>
            <Col>
                <div className="coupon-card">
                    <div className="coupon-design">
                        <div>
                            <img src={coupon} className="img-fluid" alt="" loading="lazy" width="100%" 
                            height="auto"/>
                        </div>
                        <div>
                            {/* <h3>{`Enjoy ${couponDetails.coup_percentage}% off* on your first booking with ${couponDetails.coup_code}`}</h3> */}
                            <h3>{couponDetails.coup_content}</h3>
                        </div>
                        <div className={copied ? 'discount-code discount-applied' : 'discount-code'} >
                            <div className="black-code d-none">{couponDetails.coup_code}</div>
                            {copied ? (
                                <div id="cpnBtn" className="copy">CODE COPIED</div>
                            ) : (
                                <CopyToClipboard className="coupon-row" text={couponDetails.coup_code} onCopy={handleCopy}>
                                    <div id="cpnBtn" className="copy">
                                        COPY CODE <i className="fas fa-clone"></i>
                                    </div>
                                </CopyToClipboard>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
export default Coupon;
