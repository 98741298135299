import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import BASE_URLS from '../../baseUrl';

export const Highlights = ({ proData }) => {
  const [resultProdCont, setTotProdCont] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const mainApiUrl = BASE_URLS.main;

  // useEffect(() => {
  //   if (proData.product_slug) {
  //     setTotProdCont(proData.content1);
  //     setIsLoading(false); // Set loading to false when data is fetched
  //   }
  // }, [proData]);

  useEffect(() => {
    const getCurrAddr = async () => {
      if (proData.product_slug) {
        try {
          const res = await fetch(`${mainApiUrl}/get_products_detail_content.php?product_slug=${proData.product_slug}`);
          const data = await res.json();
          setTotProdCont(data.content1);
        } catch (error) {
          console.error('Error fetching contact details:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    getCurrAddr();
  }, [mainApiUrl, proData]);

  return (
    <span>
      {isLoading ? ( // Render skeleton if loading
        <div className="highlights" id="highlights">
          <Skeleton animation="wave" variant="text" width="100%" height={50} className="mb-1" />
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} className="mb-5" />
        </div>
      ) : (
        resultProdCont.map((item, index) => (
          <div className="highlights" id="highlights" key={index}>
            <h2 className=" mt-5 mb-4" style={{ fontWeight: '800' }} >{item.product_head}</h2>
            <span dangerouslySetInnerHTML={{ __html: item.product_content }}></span>
          </div>
        ))
      )}
    </span>
  );
};
