import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { SocialModal } from "./socialModal";
import { useCurr } from "../../Contexts/currContext";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

export const ActivityName = ({ proData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showMobileComponent, setShowMobileComponent] = useState(false);
  const [showSocialModal, setShowSocialModal] = useState(false);
  const { selectedCurrency, currConvert } = useCurr();
  const openSocialModal = () => {
    setShowSocialModal(true);
  };

  const closeSocialModal = () => {
    setShowSocialModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setShowMobileComponent(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [proDatas, setProd] = useState([]);
  const [prodTags, setProdTags] = useState([]);

  useEffect(() => {
    if (proData) {
      setProd(proData);
    }
    if (proData.pro_arr) {
      setProdTags(proData.pro_arr);
    }
    setIsLoading(false);
  }, [proData]);

  return (
    <Col lg={12} md={12} className="article">
      {isLoading ?
        <Skeleton animation="wave" variant="text" width="30%" height={30} />
        :
        <div className="activity-heading">
          <h1 className="title">{proDatas.product_name}</h1>
          <p
            className="font-weight-bold text-blue view-modal mobile-block"
            onClick={openSocialModal}
          >
            <i className="bi bi-share-fill"></i>
          </p>
        </div>
      }
      <div className="mobile-block pb-3 pb-sm-2">
        <div className="d-flex justify-content-between star-rating align-items-center">
          <span>
            <small className="text-muted">from</small>{" "}
            {proData.original_price1 > proData.min_original_price ? (
              <div className="containers pb-2 d-flex align-items-center justify-content-between text-muted">
                <s >
                  <span className="currency">{selectedCurrency}</span>{" "}
                  {currConvert(proData.original_price1)}
                </s>
              </div>
            ) : null}
            <span className="price">
              {selectedCurrency} {currConvert(proData.min_original_price)} {proData.min_original_price < proData.original_price1 ? (<span className="hot_badge">{proData.discount}</span>) : null}

            </span>
          </span>
          {proDatas.rev_avg && proDatas.rev_avg > 0 ? (
            <p className="mb-0">
              <i className="fa fa-star"></i> {proDatas.rev_avg.toFixed(1)}{" "}
              <a href="#customer-reviews" className="small">
                {proDatas.rev_id_cnt} Reviews
              </a>
            </p>
          ) : (
            null
          )}
        </div>
      </div>
      {isLoading ?
        <div className="d-flex gap-2 mb-2">
          <Skeleton animation="wave" variant="text" width="10%" height={30} />
          <Skeleton animation="wave" variant="text" width="10%" height={30} />
          <Skeleton animation="wave" variant="text" width="10%" height={30} />
        </div>
        :
        <div className="d-flex mb-2 justify-content-between star-rating align-items-center">
          <div className="pb-4 pb-sm-2">
            {prodTags.map((item, index) => (
              <span className="badge bg-secondary pt-2 pb-2" key={index}>
                {item.pro_tag_name}
              </span>
            ))}
          </div>
          {showMobileComponent && proDatas.rev_avg && proDatas.rev_avg > 0 ? (
            <p className="mb-0">
              <i className="fa fa-star"></i> {proDatas.rev_avg.toFixed(1)}{" "}
              <a href="#customer-reviews" className="small">
                {proDatas.rev_id_cnt} Reviews
              </a>
            </p>
          ) : (
            null
          )}
        </div>
      }
      <SocialModal showSocial={showSocialModal} onHide={closeSocialModal} />
    </Col>
  );
};
