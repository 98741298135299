import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import SearchResult from '../Home/searchResult';
import { Col, Container, Row } from 'react-bootstrap';
import BASE_URLS from '../../baseUrl';
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

function SubBanner ({destId}) {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  // const [hasFocus, setFocus] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const formRef = useRef(null);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSearchQuery1(''); // Reset search query on modal close
  };
  const handleShow = () => setShow(true);
  const [destData2, setCityData] = useState([]);
  const [destData3, setCityData1] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCities = async () => {
      const res = await fetch(`${mainApiUrl}/get_cities_data.php?city_id=`+destId);
      const getdata = await res.json();
      setCityData(getdata);
      setCityData1(getdata.country_name_data);
      setIsLoading(false);
    }
    if (destId > 0) {
      getCities();
    }
    if (!show) {
      setSearchQuery1(''); // Reset search query when the modal is closed
    }
    
  }, [destId, mainApiUrl], []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowSearchResults(false);
        if (formRef.current.querySelector('input[type="search"]')) {
          formRef.current.querySelector('input[type="search"]').value = '';
        }
      }
    };
  
    document.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [formRef]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowSearchResults(true);
  };

  return (
    <section className="hero sub-page">

      <img loading="lazy" src={destData2.city_img} alt={destData2.city_name} width="100%" height="auto"/>

      <Container>
      {isLoading
      ? <Row>
          <Col lg={12} md={12} sm={12}>
            <center>
              <Skeleton animation="wave" variant="text" width="20%" height={20} />
              <Skeleton animation="wave" variant="text" width="15%" height={40} className="mb-1" />
              <Skeleton animation="wave" variant="text" width="40%" height={80} />
            </center>
          </Col>
        </Row> :
        <Row>
          <Col lg={12} md={12} sm={12}>
            <p className='text-center font-weight-bold'>Book things to do in</p>
            <h1 className="text-white text-center">{destData2.city_name} 
             <span onClick={handleShow}>
               <i className="bi bi-arrow-down-circle-fill"></i>
            </span></h1>
          </Col>
          <Col lg={{ span: 6, offset: 3 }}>
            <form action="#" ref={formRef} className="position-relative" onSubmit={handleFormSubmit}>
              <div className="sign-up-form d-flex">
                <input type="search" className="form-control location-search" placeholder="Search for destinations or activities..." onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setShowSearchResults(true)}/>
                <button type="submit" name='search_products' className="btn btn-primary"><i className="bi bi-search"></i></button>
              </div>
              {showSearchResults && <SearchResult searchQuery={searchQuery} />}
            </form>
          </Col>
        </Row>
      }
      </Container>
      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" id="things-to-do" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <form className="w-100"  action="#" >
						  <div className="position-relative d-flex align-items-center select-date">
							   <span className="svg-icon svg-icon-2 position-absolute mx-4">
								 <i className="bi bi-search"></i>
							   </span>
							   <input type="search" className="form-control ps-12" placeholder="Search for destination"  value={searchQuery1}
                  onChange={(e) => setSearchQuery1(e.target.value)} />
						  </div>
					  </form>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
  {destData3.map((item, index) => {
    const filteredData = item.city_data.filter(
      (item2) =>
        item2.city_name.toLowerCase().includes(searchQuery1.toLowerCase()) || // Check for city match
        item.country_name.toLowerCase().includes(searchQuery1.toLowerCase()) // Check for country match
    );

    // Check if filteredData is empty
    if (filteredData.length > 0) {
      return (
        <div className="region pb-4" key={index}>
          <h5>{item.country_name}</h5>
          {filteredData.map((filteredItem, index2) => (
            <span key={index2}>
              <Link to={`${webUrl}things-to-do/${filteredItem.city_slug}`} onClick={() => setShow(false)}>
                <input type="radio" className="btn-check" name={`options-outlined-${index}`} id={`radio-${index}-${index2}`} autoComplete="off" key={index2} />
                <label className="btn btn-outline-primary" htmlFor={`radio-${index}-${index2}`}>
                  {filteredItem.city_name}
                </label>
              </Link>
            </span>
          ))}
        </div>
      );
    }

    // If filteredData is empty, set a flag to indicate no records found
    return null;
  })}

  {/* Display "No records found" message if no matching cities were found */}
  {destData3.every(item => {
    const filteredData = item.city_data.filter(
      (item2) =>
        item2.city_name.toLowerCase().includes(searchQuery1.toLowerCase()) || // Check for city match
        item.country_name.toLowerCase().includes(searchQuery1.toLowerCase()) // Check for country match
    );
    return filteredData.length === 0;
  }) && (
    <div className="region pb-4">
      <h5>No records found...</h5>
    </div>
  )}
</Modal.Body>

      </Modal>
    </section>
  );
}

export default SubBanner;