import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Contexts/AuthContext";
import { FilterProvider } from "./Contexts/filterContext";
import { WishlistProvider } from "./Contexts/WishlistContext";
import { CurrencyProvider } from "./Contexts/currContext";
import { ContactProvider } from "./Contexts/ContactContext";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <BrowserRouter>
      <FilterProvider>
        <WishlistProvider>
          <ContactProvider>
            <CurrencyProvider>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </CurrencyProvider>
          </ContactProvider>
        </WishlistProvider>
      </FilterProvider>
    </BrowserRouter>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
