import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CheckoutSidebar } from "./CheckoutSidebar";
import { CheckoutAddonSidebar } from "./CheckoutAddonSidebar";
import { CheckoutForm } from "./CheckoutForm";
import BASE_URLS from "../../baseUrl";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_live_51PaEFlDoNoJ3VD6oQaF0vPtKLNxpi9PSxxIC4Jxs0i1Vdkc7VZNmZDPjWfA8yNL0wDmGgcxPMqpUteDhpb3qwC9m008OgrOEvg');
function CheckOut() {
  const webUrl = BASE_URLS.webUrl;
  const location = useLocation();
  const state = location.state;
  const bookingDetails = state ? state.bookingDetails : null;
  const [discountPercent, setDiscountPercent] = useState(0);
  const [bookingDetails_addon, setBookingDetailsAddon] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [options, setOptions] = useState(null);
  const payUrl = BASE_URLS.payUrl;
  const [elementsKey, setElementsKey] = useState(clientSecret);
  const [customerId, setcustomerId] = useState('');
  const [prevAmt, setprevAmt] = useState(0);

  const createPaymentIntent = async (amount, desc) => {
    if (prevAmt != amount) {

      setprevAmt(amount);
      try {
        const response = await fetch(`${payUrl}/webadmin/gateway/stripe/genStripeKey.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            amount: amount, // amount in fils
            description: desc
          })
        });
        const data = await response.json();
        setClientSecret(data.clientSecret);
        setcustomerId(data.customerId);
        setOptions({
          clientSecret: data.clientSecret,
        });
        setElementsKey(data.clientSecret);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    }
  };
  const updatePaymentIntent = async (amount, desc, prod, customerDetails) => {
      try {
        const updateResponse = await fetch(`${payUrl}/webadmin/gateway/stripe/update-intent.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: amount,
            paymentIntentId: desc.split('_secret_')[0],
            description: prod,
            customerName: customerDetails.first_name,
            customerEmail: customerDetails.email,
            customerPhone: customerDetails.actual_number,
            customerId: customerId,
          }),
        });
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
  };
  const totalAfterDiscount = (Amount, percentage) => {
    return Amount - (Amount * percentage) / 100;
  };

  useEffect(() => {
    createPaymentIntent(10 * 100, '');
  }, []);


  if (!clientSecret) {
    return (
      <div id="preloader"></div>
    )
  }

  return (
    <div className="body-content">
      <Helmet>
        <title>Checkout | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Secure Checkout</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className="checkout">
        <Container>
          <Row className="gy-4">
            <Col
              xs={12}
              lg={7}
              md={7}
              className="order-lg-1 order-md-1 order-sm-2"
            >
              <Elements stripe={stripePromise} options={options} key={elementsKey}>
                <CheckoutForm
                  bookingDetails={bookingDetails}
                  discountPercent={discountPercent}
                  setDiscountPercent={setDiscountPercent}
                  totalAfterDiscount={totalAfterDiscount}
                  bookingDetails_addon={bookingDetails_addon}
                  clientSecretKey={clientSecret}
                  createPaymentIntent={createPaymentIntent}
                  updatePaymentIntent={updatePaymentIntent}
                />
              </Elements>
            </Col>
            <Col
              xs={12}
              lg={5}
              md={5}
              className="order-lg-2 order-md-2 order-sm-1"
            >
              <CheckoutSidebar
                bookingDetails_addon={bookingDetails_addon}
                bookingDetails={bookingDetails}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                totalAfterDiscount={totalAfterDiscount}
              />

              <CheckoutAddonSidebar
                bookingDetails={bookingDetails}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                totalAfterDiscount={totalAfterDiscount}
                bookingDetails_addon={bookingDetails_addon}
                setBookingDetailsAddon={setBookingDetailsAddon}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
export default CheckOut;
