import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import BASE_URLS from "../../baseUrl";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

function TrailerBox({ destId }) {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;

  const [cityBox1, setBox1Data] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getBox1 = async () => {
      try {
        const res1 = await fetch(
          `${mainApiUrl}/get_cities_data.php?city_id=` + destId
        );
        const getBox1data = await res1.json();
        setBox1Data(getBox1data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (destId > 0) {
      getBox1();
    }
    
  }, [mainApiUrl, destId]);

  return (
    <section>
      <Container>
        {loading ? (
          <Row className="justify-content-center gy-3">
            {[...Array(3)].map((_, index) => (
              <Col lg={4} md={4} sm={12} key={index}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width="100%"
                  height="300px"
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="justify-content-center gy-3">
            {cityBox1.box1 &&
            cityBox1.box1.product_name !== "" &&
            cityBox1.box1 != "" ? (
              <Col lg={4} md={4} sm={12}>
                <div className="trailer_box">
                  <Link to={`${webUrl}activity/${cityBox1.box1.product_slug}`}>
                    <img
                      loading="lazy"
                      className="img-fluid city-high"
                      src={cityBox1.box1.product_image}
                      alt=""
                      width="100%" 
                      height="auto"
                    />
                    <div className="desc">
                      <div className="subtitle">Whats's new </div>
                      <h1 className="">{cityBox1.box1.product_name}</h1>
                      <div className="line"></div>
                    </div>
                  </Link>
                </div>
              </Col>
            ) : null}
            {cityBox1.box2 &&
            cityBox1.box2.product_name_pro !== "" &&
            cityBox1.box2 != "" ? (
              <Col lg={4} md={4} sm={12}>
                <div className="trailer_box">
                  <Link
                    to={`${webUrl}activity/${cityBox1.box2.product_slug_pro}`}
                  >
                    <img
                      loading="lazy"
                      className="img-fluid city-high"
                      src={cityBox1.box2.product_image_pro}
                      alt=""
                      width="100%" 
                      height="auto"
                    />
                    <div className="desc">
                      <div className="subtitle">Promotion</div>
                      <h1>{cityBox1.box2.product_name_pro}</h1>
                      <div className="line"></div>
                    </div>
                  </Link>
                </div>
              </Col>
            ) : null}
            {cityBox1.box3 &&
            cityBox1.box3.product_name_ourpick !== "" &&
            cityBox1.box3 != "" ? (
              <Col lg={4} md={4} sm={12}>
                <div className="trailer_box">
                  <Link
                    to={`${webUrl}activity/${cityBox1.box3.product_slug_ourpick}`}
                  >
                    <img
                      loading="lazy"
                      className="img-fluid city-high"
                      src={cityBox1.box3.product_image_ourpick}
                      alt=""
                      width="100%" 
                      height="auto"
                    />
                    <div className="desc">
                      <div className="subtitle">Rathin's pick </div>
                      <h1>{cityBox1.box3.product_name_ourpick}</h1>
                      <div className="line"></div>
                    </div>
                  </Link>
                </div>
              </Col>
            ) : null}
          </Row>
        )}
      </Container>
    </section>
  );
}

export default TrailerBox;
