import React, { useState, useEffect } from 'react';
import BlogSidebar from './BlogSidebar';
import BlogContent from './BlogContent';
import { useLocation, Link, useParams } from "react-router-dom";
import BASE_URLS from '../../baseUrl';
import { Helmet } from "react-helmet-async";

function BlogDetails() {
    const location = useLocation();
    const [blogDetail, setBlogDetail] = useState();
    const [activityList, setActivityList] = useState();
    

    const webUrl = BASE_URLS.webUrl;
    const mainApiUrl = BASE_URLS.main;

    const { blogSlug } = useParams(); // Extract slug from URL
    console.log(blogSlug);

    const fetchBlogDetails = async () => {
        const setAuthUser = localStorage.getItem("setAuthUser");
        const dataToSend = {
            setAuthUser: setAuthUser,
        };
        try {
            // API to fetch blog details by slug
            const blogResponse = await fetch(
                `${mainApiUrl}/get_blog_details.php?blog_slug=${blogSlug}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dataToSend),
                }
            );
            const blogData = await blogResponse.json();

            setBlogDetail(blogData.blog_details);

            if (blogData.blog_details.blog_city_id) {
                const activitiesResponse = await fetch(
                    `${mainApiUrl}/get_products.php?&city_id=${blogData.blog_details.blog_city_id}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataToSend),
                    }
                );
                const activitiesData = await activitiesResponse.json();
                setActivityList(activitiesData.prod_list);
            }

        } catch (error) {
            console.error("Error fetching blog details:", error);
        }
    };

    useEffect(() => {
        fetchBlogDetails();
    }, [blogSlug]);

    return (
        <div className="body-content">
             <Helmet>
                <title>Rathin Blog</title>
            </Helmet>
            <div className="breadcrumbs">
                <div className='container'>
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><Link to={webUrl}>Home</Link></li>
                            <li><Link to={`${webUrl}blog`}>Blog</Link></li>
                            <li>{blogDetail?.blog_title}</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className='blog_details'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <BlogContent blogDetails={blogDetail} />
                        </div>
                        {/* <div className='col-lg-3 col-md-4 col-sm-12'>
                            <BlogSidebar activityList={activityList} />
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BlogDetails;
