import React, { useState, useEffect, useMemo, useRef } from "react";
import visa from "../../Images/visa.png";
import mastercard from "../../Images/mastercard.png";
import gpay from "../../Images/gpay.png";
import applepay from "../../Images/applepay.png";
import americanexpress from "../../Images/americanexpress.png";
import unionpay from "../../Images/unionpay.png";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../Contexts/AuthContext";
import { useCurr } from "../../Contexts/currContext";
import LoginModal from "../LoginModal";
import SignupModal from "../SignupModal";
import SigninModal from "../SigninModal";
import BASE_URLS from "../../baseUrl";
// import googlePayJs from "../../googlePayJs"
import { useNavigate } from "react-router-dom";
import TapPayment from "./TapPayment";
import { PaymentFunctions } from "./PaymentFunctions";
// import Loader from "../../Loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PaymentLoader } from "../../PaymentLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
// import {
//     ApplePayButton,
//     ThemeMode,
//     SupportedNetworks,
//     Scope,
//     Environment,
//     Locale,
//     ButtonType,
//     Edges,
// } from "@tap-payments/apple-pay-button";
import { TypeItalic } from "react-bootstrap-icons";
import StripePayment from "./StripePayment";
import { CardElement, useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";

// import GoSellElementsDemo from "./googlePay";

export const CheckoutForm = (props) => {
    const webUrl = BASE_URLS.webUrl;
    const lastAccessTime = localStorage.getItem("lastAccessTime");
    const setAuthUser = localStorage.getItem("setAuthUser");
    const mainApiUrl = BASE_URLS.main;
    const payUrl = BASE_URLS.payUrl;
    const [tap, setTap] = useState(null);
    const [card, setCard] = useState(null);
    const [form, setForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [prodId, setProdId] = useState(null);
    const [prodIdAddon, setProdIdAddon] = useState(null);

    const navigate = useNavigate();
    const [travelers_name, setpronameArr] = useState([]);
    const [proDatadob, setprodobArr] = useState([]);
    const [proDatamob, setpromobArr] = useState([]);
    const [proDataDoc, setproDataDoc] = useState([]);
    const [proinfotype, travelersinfotype] = useState(0);
    const [prootherinfoArr, setotherinfoarr] = useState([]);

    const [travelers_nameAddon, setpronameArrAddon] = useState([]);
    const [proDatadobAddon, setprodobArrAddon] = useState([]);
    const [proDatamobAddon, setpromobArrAddon] = useState([]);
    const [proDataDocAddon, setproDataDocAddon] = useState([]);
    const [proinfotypeAddon, travelersinfotypeAddon] = useState(0);
    const [prootherinfoArrAddon, setotherinfoarrAddon] = useState([]);

    const [isSafari, setIsSafari] = useState(false);

    const { isLoggedIn, setIsLoggedIn, setIsLoggedInWithGmail } = useAuth();
    const { selectedCurrency, currConvert, currConvertOG } = useCurr();
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // useEffect(() => {
    //     const userAgent = navigator.userAgent;
    //     const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);
    //     setIsSafari(isSafariBrowser);
    // }, []);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (props.bookingDetails.product_id_num) {
            setProdId(props.bookingDetails.product_id_num);

            if (prodId > 0) {
                const gettravel = async () => {
                    const res = await fetch(
                        `${mainApiUrl}/get_products_traveler_information.php?product_id=${prodId}`
                    );
                    const getdata = await res.json();
                    setprodobArr(getdata.travelers_dob_dts);
                    setpromobArr(getdata.travelers_mob_dts);
                    setpronameArr(getdata.travelers_name);
                    setproDataDoc(getdata.travelers_name_doc);
                    travelersinfotype(getdata.travelers_info_type);
                    setotherinfoarr(getdata.other_info_dts);
                };

                gettravel();
            }
        }
    }, [mainApiUrl, prodId]);

    useEffect(() => {
        if (props.bookingDetails_addon.product_id_num) {
            setProdIdAddon(props.bookingDetails_addon.product_id_num);
            setDiscountedAmountAddon(props.bookingDetails_addon.totalAmount);
            if (prodIdAddon > 0) {
                const gettravelAddon = async () => {
                    const res = await fetch(
                        `${mainApiUrl}/get_products_traveler_information.php?product_id=${prodIdAddon}`
                    );
                    const getdata = await res.json();
                    setprodobArrAddon(getdata.travelers_dob_dts);
                    setpromobArrAddon(getdata.travelers_mob_dts);
                    setpronameArrAddon(getdata.travelers_name);
                    setproDataDocAddon(getdata.travelers_name_doc);
                    travelersinfotypeAddon(getdata.travelers_info_type);
                    setotherinfoarrAddon(getdata.other_info_dts);
                };

                gettravelAddon();
            }
        }
    }, [props.bookingDetails_addon, prodIdAddon]);

    const [identifier, setIdentifier] = useState(null);

    useEffect(() => {
        // Check if an identifier already exists in local storage
        const existingIdentifier = localStorage.getItem('identifier');
        if (existingIdentifier) {
            setIdentifier(existingIdentifier);
        } else {
            // If no identifier exists, generate a new one
            generateAndStoreIdentifier();
        }
    }, []);

    const generateAndStoreIdentifier = () => {
        const newIdentifier = generateCurrentDateTimeIdentifier();
        localStorage.setItem('identifier', newIdentifier);
        setIdentifier(newIdentifier);
    };

    const generateCurrentDateTimeIdentifier = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

        return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
    };

    const [selectedOption, setSelectedOption] = useState("option-1"); // Default to second option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.id);
    };

    const [applePayOk, setApplePayOk] = useState(0);
    const [insertedOrderId, setInsertedOrderId] = useState("");

    // const applePayButtonRef = useRef(null);
    // console.log(props.clientSecretKey);

    const handleFormSubmitCard = async (event, orderDetails, bookingDetails_addon) => {
        event.preventDefault();

        // Proceed with your form submission logic
        try {
            if (selectedOption === "option-2") {
                //apple pay
                console.log("option-2");
                setApplePayOk(1);
                return; // Stop further execution as Apple Pay will handle it
            } else {
                // console.log(customerDetails.mobile_number.length);
                if (customerDetails.mobile_number.length <= 10) {
                    alert('Please Enter Mobile No.!');
                    return;
                }
                console.log("option-1");
                if (!stripe || !elements) {
                    return;
                }

                setLoading(true);

                const usr_id = localStorage.getItem("setAuthUser");
                const paymentFunc = PaymentFunctions();
                var discountedAmountOG = parseFloat(currConvertOG(discountedAmount));
                var discountedAmountOGAddon = parseFloat(currConvertOG(discountedAmountAddon));

                var amountInAED = parseFloat(discountedAmountOG.toFixed(2));
                var amountInFils = Math.round(amountInAED * 100);
                // props.createPaymentIntent(amountInFils);

                // setLoading(true);
                try {
                    const insertorderDetails = {
                        // ... (rest of the orderDetails)
                        order_no: "-",
                        ...(usr_id > 0 && { usr_id: usr_id }),
                        products_id: orderDetails.product_id_num,
                        actual_price: orderDetails.actual_amount,
                        total_price: discountedAmountOG.toFixed(2),
                        payment_amount: discountedAmountOG.toFixed(2),
                        total_priceAddon: discountedAmountOGAddon.toFixed(2),
                        payment_amountAddon: discountedAmountOGAddon.toFixed(2),
                        first_name: customerDetails.first_name,
                        last_name: customerDetails.last_name,
                        email_id: customerDetails.email,
                        mobile_no: customerDetails.mobile_number,
                        country_code: customerDetails.country_code,
                        actual_number: customerDetails.actual_number,
                        promo_code: promoCode,
                        promo_percentage: props.discountPercent,
                        special_request: customerDetails.request || "",
                        booking_date: orderDetails.booking_date,
                        booking_time: orderDetails.booking_time,
                        selectedTickets: orderDetails.selectedTickets,
                        paymentType: selectedOption,
                        travellerDetails: travellerDetails,
                        travellerDetailsAddon: travellerDetailsAddon,
                        OtherInfoDetails: OtherInfoDetails,
                        OtherInfoDetailsAddon: OtherInfoDetailsAddon,
                        payMentFrom: 2,
                        newIdentifier: identifier,
                        bookingDetails_addon: bookingDetails_addon,
                        clientSecretKey: props.clientSecretKey.split('_secret_')[0],
                    };

                    const orderInsert = await paymentFunc.insertOrder(insertorderDetails);

                    if (orderInsert.status) {
                        var discountedAmountOG = parseFloat(currConvertOG(discountedAmount));
                        var amountInAED = parseFloat(discountedAmountOG.toFixed(2));
                        var amountInFils = Math.round(amountInAED * 100);
                        console.log("response clientSecret:", props.clientSecretKey);

                        // Update PaymentIntent with final amount
                        const updateResponse = await fetch(`${payUrl}/webadmin/gateway/stripe/update-intent.php`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                amount: amountInFils,
                                paymentIntentId: props.clientSecretKey.split('_secret_')[0],
                                description: props.bookingDetails.product_id+' - '+orderInsert.orderNos
                            }),
                        });

                        if (!updateResponse.ok) {
                            throw new Error('Failed to update payment intent');
                        }

                        console.log("orderInsert.orderNos:", orderInsert.orderNos);
                        // Confirm payment
                        const { error, paymentIntent } = await stripe.confirmPayment({
                            elements,
                            confirmParams: {
                                payment_method_data: {
                                    billing_details: {
                                        name: `${customerDetails.first_name} ${customerDetails.last_name}`,
                                        email: customerDetails.email,
                                        phone: customerDetails.mobile_number,
                                    },
                                },
                            },
                            redirect: 'if_required', // Add this to prevent automatic redirect
                        });

                        console.log("if_required");

                        if (error) {
                            if (error.type === "card_error" || error.type === "validation_error") {
                                setErrorMessage(error.message);
                            } else {
                                setErrorMessage("An unexpected error occurred.");
                            }
                            navigate(`${webUrl}booking-failure`);
                            return;
                        }

                        // If payment is successful
                        if (paymentIntent && paymentIntent.status === "succeeded") {
                            console.log("paymentIntent success", paymentIntent);
                            // Update order with payment intent details
                            try {
                                const updateResponse = await fetch(`${mainApiUrl}/updateOrder.php`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        order_id: orderInsert.order_id,
                                        client_secret: paymentIntent,
                                    }),
                                });

                                if (!updateResponse.ok) {
                                    throw new Error('Failed to update order');
                                } else {
                                    window.location.href = `/redirect_url?order_id=${orderInsert.order_id}&order_no=${paymentIntent.id}`;
                                }

                                // Redirect to success page
                            } catch (updateError) {
                                console.error("Error updating order:", updateError);
                                navigate(`${webUrl}booking-failure`);
                            }
                        } else {
                            // Handle other payment intent statuses
                            navigate(`${webUrl}booking-failure`);
                        }


                        // let stripePayload = {
                        //     items: {
                        //         paymentMethodId: props.clientSecretKey,
                        //         amount: amountInFils,
                        //         name: customerDetails.first_name + customerDetails.last_name,
                        //         email: customerDetails.email,
                        //         phone: {
                        //             number: customerDetails.mobile_number,
                        //         },
                        //     }
                        // }
                        // fetch(`${payUrl}/webadmin/gateway/stripe/charge.php`, {
                        //     method: "POST",
                        //     body: JSON.stringify(stripePayload)
                        // })
                        //     .then(async (resp) => {
                        //         let response = await resp.json();
                        //         // Here you should confirm the payment on frontend
                        //         stripe.confirmPayment({
                        //             elements,
                        //             confirmParams: {
                        //                 // Make sure to change this to your payment completion page
                        //                 return_url: `/redirect_url?order_id=${orderInsert.order_id}`,
                        //             },
                        //         })
                        //             // stripe.confirmCardPayment(response.clientSecret, {
                        //             //     payment_method: {
                        //             //         card: PaymentElement,
                        //             //     },
                        //             // })
                        //             .then((result) => {
                        //                 if (result.error) {
                        //                     window.location.href = `/redirect_url?order_id=${orderInsert.order_id}`;
                        //                 } else {
                        //                     fetch(`${mainApiUrl}/updateOrder.php`, {
                        //                         method: "POST",
                        //                         body: JSON.stringify({
                        //                             order_id: orderInsert.order_id,
                        //                             client_secret: result.paymentIntent,
                        //                         })
                        //                     });
                        //                     let OrderDetails = result?.paymentIntent;
                        //                     const order_no = result.paymentIntent.id;
                        //                     if (OrderDetails?.status === "succeeded") {
                        //                         window.location.href = `/redirect_url?order_id=${orderInsert.order_id}&order_no=${order_no}`;
                        //                     } else {
                        //                     }
                        //                 }
                        //             });
                        //     })
                        //     .catch((error) => {
                        //         console.error("Error:", error);
                        //         // Handle errors (e.g., show error message to user)
                        //     });
                        // const OrderDetails = await paymentFunc.tapChargeApi(orderPayload);

                        // const OrderDetails = await paymentFunc.tapChargeApi(orderPayload);

                    } else {
                        console.error("Error in Insert Funciton:");
                        navigate(`${webUrl}` + "booking-failure");
                    }
                } catch (error) {
                    console.error("Error in handlePayment:", error);
                    navigate(`${webUrl}` + "booking-failure");
                }
                // }
            }
        } catch (error) {
            console.error("Error creating token:", error);
            navigate(`${webUrl}` + "booking-failure");
        }
    };

    useEffect(() => {
        // Pass your public key from Tap's dashboard
        // setTap(tapInstance);

        // const elements = tapInstance.elements({});
        // const style = {
        //     base: {
        //         color: "#535353",
        //         lineHeight: "18px",
        //         fontFamily: "'Nunito Sans', 'Helvetica', 'Arial', sans-serif",
        //         fontSmoothing: "antialiased",
        //         fontSize: "16px",
        //         border: "1px solid red",
        //         "::placeholder": {
        //             color: "rgba(0, 0, 0, 0.26)",
        //             fontSize: "16px",
        //         },
        //     },
        //     invalid: {
        //         color: "red",
        //     },
        // };

        // Input labels/placeholders
        // const labels = {
        //     cardNumber: "Card Number",
        //     expirationDate: "MM/YY",
        //     cvv: "CVV",
        //     cardHolder: "Card Holder Name",
        // };

        // Payment options
        // const paymentOptions = {
        //     currencyCode: ["KWD", "USD", "SAR"],
        //     labels: labels,
        //     TextDirection: "ltr",
        // };

        // Create element, pass style and payment options
        // const cardElement = elements.create(
        //     "card",
        //     { style: style },
        //     paymentOptions
        // );

        // setCard(cardElement);

        // Mount element
        // const elementContainer = document.getElementById("element-container");
        // Clear existing elements inside #element-container
        // elementContainer.innerHTML = " ";
        // Mount element

        // cardElement.mount("#element-container");

        // Card change event listener
        // cardElement.addEventListener("change", handleCardChange);

        // setForm(document.getElementById("form-container"));
    }, []); // Run once on component mount

    const checkoutData = useMemo(() => {
        return {
            user_id: setAuthUser,
            lastAccessTime: lastAccessTime,
        };
    }, [setAuthUser, lastAccessTime]);

    useEffect(() => {
        fetch(`${mainApiUrl}/get_temp_checkout.php`, {
            method: "POST",
            body: JSON.stringify(checkoutData),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    // console.log(response);
                } else {
                    navigate(`${webUrl}` + "booking-failure");
                    throw new Error("API request failed");
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
                navigate(`${webUrl}` + "booking-failure");
            });
    }, [mainApiUrl, checkoutData]);

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const [showMobileComponent, setShowMobileComponent] = useState(false);

    const [promoCode, setPromoCode] = useState("");
    const [promoCodeValid, setPromoCodeValid] = useState("");
    const addonTotalAmount = props.bookingDetails_addon.length === 0 ? 0 : props.bookingDetails_addon.totalAmount;
    const [discountedAmount, setDiscountedAmount] = useState((props.bookingDetails?.totalAmount) || 0);
    const [discountedAmountAddon, setDiscountedAmountAddon] = useState((addonTotalAmount) || 0);
    useEffect(() => {
        // console.log(discountedAmount);
        // console.log(discountedAmountAddon);
        // console.log(props.bookingDetails?.totalAmount);
        // console.log(props.bookingDetails_addon.totalAmount);
    }, [discountedAmount, discountedAmountAddon]);

    const [selectedFile, setSelectedFile] = useState(null);

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showSigninModal, setShowSigninModal] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        country_code: "",
        mobile_number: "",
        actual_number: "",
    });
    const [travellerDetails, setTravellerDetails] = useState({
        travelers_dob: [],
        travelers_mob: [],
        travelers_names: [],
        travelers_files: [],
    });
    const [travellerDetailsAddon, setTravellerDetailsAddon] = useState({
        travelers_dobAddon: [],
        travelers_mobAddon: [],
        travelers_namesAddon: [],
        travelers_filesAddon: [],
    });
    // const [travellerDetailsfiles, setTravellerDetailsfiles] = useState({
    //     travelers_files: []
    // });
    const [OtherInfoDetails, OtherInfo] = useState({
        OtherInfo: [],
    });

    const [OtherInfoDetailsAddon, OtherInfoAddon] = useState({
        OtherInfoAddon: [],
    });
    useEffect(() => {
        const handleResize = () => {
            setShowMobileComponent(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleApplyPromoCode = async () => {
        const emailValue = document.getElementById("email");
        if (emailValue.value.trim() === "") {
            alert("Please enter your email address");

            emailValue.focus();
            return false; // Exit the function to prevent further execution
        }

        // Check if the email format is valid using regex
        const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailFormat.test(emailValue.value)) {
            alert("Please enter a valid email address");
            emailValue.value = "";
            emailValue.focus();
            return false; // Exit the function to prevent further execution
        }

        const response = await fetch(`${mainApiUrl}/check_coupon_code.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                coupon_code: promoCode,
                product_id: prodId,
                email_id: customerDetails.email,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.result === "Success") {
                const reponseCouponData = data.coupon_data;
                const newCoupType = parseInt(reponseCouponData.coup_type);
                const newCoupFixed = parseFloat(reponseCouponData.coup_fixed);
                if (newCoupType == 1) {
                    var newDiscountPercent = parseInt(reponseCouponData.coup_percentage);
                    var newDiscountPercentMain = parseInt(reponseCouponData.coup_percentage);
                    var newDiscountPercentAddon = parseInt(reponseCouponData.coup_percentage);
                    props.setDiscountPercent(newDiscountPercent);
                } else {
                    var disFinPrice = parseFloat((props.bookingDetails.totalAmount + addonTotalAmount) - newCoupFixed);
                    var disFinPriceMain = parseFloat((props.bookingDetails.totalAmount) - (newCoupFixed / 2));
                    var disFinPriceAddon = parseFloat((addonTotalAmount) - (newCoupFixed / 2));

                    var discountPercentage = (100 * ((props.bookingDetails.totalAmount + addonTotalAmount) - disFinPrice) / (props.bookingDetails.totalAmount + addonTotalAmount));
                    var discountPercentageMain = (100 * ((props.bookingDetails.totalAmount) - disFinPriceMain) / props.bookingDetails.totalAmount);
                    var discountPercentageAddon = (100 * ((addonTotalAmount) - disFinPriceAddon) / addonTotalAmount);
                    var discountPercentage = (100 * (props.bookingDetails.totalAmount - disFinPrice) / props.bookingDetails.totalAmount);
                    props.setDiscountPercent(discountPercentage);
                    var newDiscountPercent = parseFloat(discountPercentage);
                }

                if (newDiscountPercent >= 100) {
                    var newDiscountPercent = 99;
                    props.setDiscountPercent(newDiscountPercent);
                }
                // console.log(props.bookingDetails.totalAmount+' - '+newDiscountPercent);
                // Wait for the state to update
                await new Promise((resolve) => setTimeout(resolve, 0));
                const discountedAmount = props.totalAfterDiscount((props.bookingDetails.totalAmount + addonTotalAmount), newDiscountPercent);
                const discountedAmountMain = props.totalAfterDiscount((props.bookingDetails.totalAmount), newDiscountPercentMain);
                const discountedAmountAddon = props.totalAfterDiscount((addonTotalAmount), newDiscountPercentAddon);
                setPromoCodeValid("success");
                setDiscountedAmount(discountedAmount);
                setDiscountedAmountAddon(discountedAmountAddon);

            } else if (data.result === "empty") {
                // console.log('empty');
                setPromoCodeValid("");
                props.setDiscountPercent(0);
            } else {
                // console.log('error');
                setPromoCodeValid("error");
                props.setDiscountPercent(0);
            }
        } else {
            // console.log('response was not ok.');
            throw new Error("Network response was not ok.");
        }
        // };
    };

    const openLoginModal = () => {
        setShowLoginModal(true);
    };

    const closeLoginModal = () => {
        setShowLoginModal(false);
    };

    const openSignupModal = () => {
        setShowSignupModal(true);
    };

    const closeSignupModal = () => {
        setShowSignupModal(false);
    };

    const openSigninModal = () => {
        setShowSigninModal(true);
    };
    const closeSigninModal = () => {
        setShowSigninModal(false);
    };

    useEffect(() => {
        setIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");
        setIsLoggedInWithGmail(
            localStorage.getItem("isLoggedInWithGmail") === "true"
        );
    }, [setIsLoggedIn, setIsLoggedInWithGmail]);

    const handleSuccessfulLogin = () => {
        setIsLoggedIn(true); // Update the login status
        localStorage.setItem("isLoggedIn", "true"); // Store the login status
    };

    const setSuccessfulGoogleLogin = () => {
        setIsLoggedInWithGmail(true);
        localStorage.setItem("setIsLoggedInWithGmail", "true");
    };

    const handleGoogleLoginSuccess = () => {
        handleSuccessfulLogin();
        setSuccessfulGoogleLogin();
        closeLoginModal();
        closeSignupModal();
        toast.success("Login successful!", {
            position: toast.POSITION.TOP_CENTER,
        });
    };

    const handleFormSubmit = (event, orderDetails) => {
        event.preventDefault();

        // Validate the fields inside the TapPayment component
        // const cardElement = tap.getElement("card");
        // if (!cardElement || !cardElement.isValid) {
        //   // Display an error message or take appropriate action
        //   console.error("Invalid card details");
        //   return;
        // }

        // Validate the fields inside the TapPayment component
        if (!tap || !card || !tap.createToken) {
            console.error("Tap, card, or createToken is undefined");
            return;
        }

        // console.log(card);
        // return;
        // console.log("cardDetails....", card);
        // return;
        tap.createToken(card).then((result) => {
            const displayError = document.getElementById("error-handler");
            if (result.error) {
                // Inform the user if there was an error
                // console.log(result.error);
                displayError.textContent = result.error.message;
            } else {
                // Send the token to your server
                // const successElement = document.getElementById("success");
                // successElement.style.display = "block";
                // const tokenElement = document.getElementById("token");
                // tokenElement.textContent = result.id;
                // console.log("token_id...", result.id);
                // tapTokenHandler(result);
            }
        });
    };
    const handleInputTravname = (name, value, tick_name, i) => {
        setTravellerDetails((prevState) => {
            let updatedNames = [...prevState.travelers_names];
            let existingEntryIndex = updatedNames.findIndex(item => item.i === i.toString());

            let inputNameValue = '';
            let inputLNameValue = '';

            if (existingEntryIndex !== -1) {
                inputNameValue = updatedNames[existingEntryIndex].nameValue || '';
                inputLNameValue = updatedNames[existingEntryIndex].lnameValue || '';
            }

            if (name === 'travelers_name') {
                inputNameValue = value;
            } else if (name === 'travelers_lname') {
                inputLNameValue = value;
            }

            let Space = '';
            if (inputNameValue !== '' && inputLNameValue !== '') {
                Space = ' ';
            }


            const concatenatedValue = inputNameValue + Space + inputLNameValue;

            if (inputNameValue === '' && inputLNameValue === '') {
                if (existingEntryIndex !== -1) {
                    updatedNames.splice(existingEntryIndex, 1);
                }
            } else {
                if (existingEntryIndex === -1) {
                    updatedNames.push({
                        i: i.toString(),
                        tname: 'travelers_name',
                        tick_name: tick_name,
                        tvalue: concatenatedValue,
                        nameValue: inputNameValue,
                        lnameValue: inputLNameValue
                    });
                } else {
                    updatedNames[existingEntryIndex] = {
                        i: i.toString(),
                        tname: 'travelers_name',
                        tick_name: tick_name,
                        tvalue: concatenatedValue,
                        nameValue: inputNameValue,
                        lnameValue: inputLNameValue
                    };
                }
            }

            return {
                ...prevState,
                travelers_names: updatedNames
            };
        });
    };

    const handleInputTravnameAddon = (name, value, tick_name, i) => {
        setTravellerDetailsAddon((prevState) => {
            let updatedNames = [...prevState.travelers_namesAddon];
            let existingEntryIndex = updatedNames.findIndex(item => item.i === i.toString());



            let inputNameValue = '';
            let inputLNameValue = '';

            if (existingEntryIndex !== -1) {
                inputNameValue = updatedNames[existingEntryIndex].nameValue || '';
                inputLNameValue = updatedNames[existingEntryIndex].lnameValue || '';
            }

            if (name === 'travelers_nameAddon') {
                inputNameValue = value;
            } else if (name === 'travelers_lname') {
                inputLNameValue = value;
            }

            let Space = '';
            if (inputNameValue !== '' && inputLNameValue !== '') {
                Space = ' ';
            }


            const concatenatedValue = inputNameValue + Space + inputLNameValue;

            if (inputNameValue === '' && inputLNameValue === '') {
                if (existingEntryIndex !== -1) {
                    updatedNames.splice(existingEntryIndex, 1);
                }
            } else {
                if (existingEntryIndex === -1) {
                    updatedNames.push({
                        i: i.toString(),
                        tname: 'travelers_nameAddon',
                        tick_name: tick_name,
                        tvalue: concatenatedValue,
                        nameValue: inputNameValue,
                        lnameValue: inputLNameValue
                    });
                } else {
                    updatedNames[existingEntryIndex] = {
                        i: i.toString(),
                        tname: 'travelers_nameAddon',
                        tick_name: tick_name,
                        tvalue: concatenatedValue,
                        nameValue: inputNameValue,
                        lnameValue: inputLNameValue
                    };
                }
            }

            return {
                ...prevState,
                travelers_namesAddon: updatedNames
            };
        });
    };



    const handleInputTravmob = (name, value, country, tick_name, i) => {
        if (value && value.length > 0) {
            const countryCode = country.dialCode;
            const actualNumber = value.replace(countryCode, "");

            setTravellerDetails((prevState) => {
                const filteredMob = prevState.travelers_mob.filter(
                    (item) =>
                        !(
                            item.tname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                );

                const updatedMob = [
                    ...filteredMob,
                    {
                        i: i.toString(),
                        tname: name,
                        tick_name: tick_name,
                        country_code: countryCode,
                        mobile_number: value,
                        actual_number: actualNumber,
                    },
                ];

                return {
                    ...prevState,
                    travelers_mob: updatedMob,
                };
            });
        } else {
            setTravellerDetails((prevState) => ({
                ...prevState,
                travelers_mob: prevState.travelers_mob.filter(
                    (item) =>
                        !(
                            item.tname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    const handleInputTravmobAddon = (name, value, country, tick_name, i) => {
        if (value && value.length > 0) {
            const countryCode = country.dialCode;
            const actualNumber = value.replace(countryCode, "");

            setTravellerDetailsAddon((prevState) => {
                const filteredMob = prevState.travelers_mobAddon.filter(
                    (item) =>
                        !(
                            item.tname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                );

                const updatedMob = [
                    ...filteredMob,
                    {
                        i: i.toString(),
                        tname: name,
                        tick_name: tick_name,
                        country_code: countryCode,
                        mobile_number: value,
                        actual_number: actualNumber,
                    },
                ];

                return {
                    ...prevState,
                    travelers_mobAddon: updatedMob,
                };
            });
        } else {
            setTravellerDetailsAddon((prevState) => ({
                ...prevState,
                travelers_mobAddon: prevState.travelers_mobAddon.filter(
                    (item) =>
                        !(
                            item.tname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    const [selectedDate, setSelectedDate] = useState({});

    const handleInputTravdob = (name, date, tick_name, i, tname) => {
        if (date) {
            const formattedDate = format(date, "dd-MM-yyyy");

            setSelectedDate((prevState) => ({
                ...prevState,
                [name]: date,
            }));
            setTravellerDetails((prevState) => {
                const filteredDob = prevState.travelers_dob.filter(
                    (item) =>
                        !(
                            item.tname === tname &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                );

                const updatedDob = [
                    ...filteredDob,
                    {
                        tname: tname,
                        dob: formattedDate,
                        tick_name: tick_name,
                        i: i.toString(),
                    },
                ];

                return {
                    ...prevState,
                    travelers_dob: updatedDob,
                };
            });
        } else {
            setSelectedDate((prevState) => ({
                ...prevState,
                [name]: null,
            }));

            setTravellerDetails((prevState) => ({
                ...prevState,
                travelers_dob: prevState.travelers_dob.filter(
                    (item) =>
                        !(
                            item.tname === tname &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    const handleInputTravdobAddon = (name, date, tick_name, i, tname) => {
        if (date) {
            const formattedDate = format(date, "dd-MM-yyyy");

            setSelectedDate((prevState) => ({
                ...prevState,
                [name]: date,
            }));
            setTravellerDetailsAddon((prevState) => {
                const filteredDob = prevState.travelers_dobAddon.filter(
                    (item) =>
                        !(
                            item.tname === tname &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                );

                const updatedDob = [
                    ...filteredDob,
                    {
                        tname: tname,
                        dob: formattedDate,
                        tick_name: tick_name,
                        i: i.toString(),
                    },
                ];

                return {
                    ...prevState,
                    travelers_dobAddon: updatedDob,
                };
            });
        } else {
            setSelectedDate((prevState) => ({
                ...prevState,
                [name]: null,
            }));

            setTravellerDetailsAddon((prevState) => ({
                ...prevState,
                travelers_dobAddon: prevState.travelers_dobAddon.filter(
                    (item) =>
                        !(
                            item.tname === tname &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    const handleInputTrav1 = (name, value, type, tick_name, i, e) => {
        if (type === "file" && value && value.length > 0) {
            const file = value[0];
            if (!(file instanceof Blob)) {
                console.error("Parameter is not of type Blob:", file);
                return false;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result.split(",")[1];
                const fileExtension = file.name.split(".").pop();
                const allowedExtensions = ['pdf', 'png', 'jpeg', 'jpg', 'webp'];
                if (!allowedExtensions.includes(fileExtension)) {
                    alert("Invalid file");
                    e.target.value = "";
                    return false;
                }
                const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
                if (fileSizeMB > 1) {
                    alert("Please upload images that are 1MB or smaller.");
                    e.target.value = "";
                    return false;
                }
                setTravellerDetails((prevState) => ({
                    ...prevState,
                    travelers_files: [
                        ...prevState.travelers_files.filter(
                            (item) =>
                                !(
                                    item.tname === name &&
                                    item.tick_name === tick_name &&
                                    item.i === i.toString()
                                )
                        ),
                        {
                            tname: name,
                            tvalue: base64Data,
                            tick_name: tick_name,
                            i: i.toString(),
                            extension: fileExtension,
                        },
                    ],
                }));
            };

            reader.readAsDataURL(file);
        } else {

            setTravellerDetails((prevState) => ({
                ...prevState,
                travelers_files: prevState.travelers_files.filter(
                    (item) =>
                        !(
                            item.tname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    const handleInputTrav1Addon = (name, value, type, tick_name, i, e) => {
        if (type === "file" && value && value.length > 0) {
            const file = value[0];
            if (!(file instanceof Blob)) {
                console.error("Parameter is not of type Blob:", file);
                return false;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result.split(",")[1];
                const fileExtension = file.name.split(".").pop();
                const allowedExtensions = ['pdf', 'png', 'jpeg', 'jpg', 'webp'];
                if (!allowedExtensions.includes(fileExtension)) {
                    alert("Invalid file");
                    e.target.value = "";
                    return false;
                }
                const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
                if (fileSizeMB > 1) {
                    alert("Please upload images that are 1MB or smaller.");
                    e.target.value = "";
                    return false;
                }
                setTravellerDetailsAddon((prevState) => ({
                    ...prevState,
                    travelers_filesAddon: [
                        ...prevState.travelers_filesAddon.filter(
                            (item) =>
                                !(
                                    item.tname === name &&
                                    item.tick_name === tick_name &&
                                    item.i === i.toString()
                                )
                        ),
                        {
                            tname: name,
                            tvalue: base64Data,
                            tick_name: tick_name,
                            i: i.toString(),
                            extension: fileExtension,
                        },
                    ],
                }));
            };

            reader.readAsDataURL(file);
        } else {

            setTravellerDetailsAddon((prevState) => ({
                ...prevState,
                travelers_filesAddon: prevState.travelers_filesAddon.filter(
                    (item) =>
                        !(
                            item.tname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    useEffect(() => {
        // console.log(travellerDetails);
    }, [travellerDetails, travellerDetailsAddon]);

    const handleOtherInfo = (name, value, tick_name, i) => {
        // console.log(value);
        if (value && value.length > 0) {
            OtherInfo((prevState) => {
                const filteredName = prevState.OtherInfo.filter(
                    (item) =>
                        !(
                            item.oname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                );

                const updateOtherInfodName = [
                    ...filteredName,
                    {
                        i: i.toString(),
                        oname: name,
                        tick_name: tick_name,
                        ovalue: value,
                    },
                ];

                return {
                    ...prevState,
                    OtherInfo: updateOtherInfodName,
                };
            });
        } else {
            OtherInfo((prevState) => ({
                ...prevState,
                OtherInfo: prevState.OtherInfo.filter(
                    (item) =>
                        !(
                            item.oname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    const handleOtherInfoAddon = (name, value, tick_name, i) => {
        // console.log(value);
        if (value && value.length > 0) {
            OtherInfoAddon((prevState) => {
                const filteredName = prevState.OtherInfoAddon.filter(
                    (item) =>
                        !(
                            item.oname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                );

                const updateOtherInfodName = [
                    ...filteredName,
                    {
                        i: i.toString(),
                        oname: name,
                        tick_name: tick_name,
                        ovalue: value,
                    },
                ];

                return {
                    ...prevState,
                    OtherInfoAddon: updateOtherInfodName,
                };
            });
        } else {
            OtherInfoAddon((prevState) => ({
                ...prevState,
                OtherInfoAddon: prevState.OtherInfoAddon.filter(
                    (item) =>
                        !(
                            item.oname === name &&
                            item.tick_name === tick_name &&
                            item.i === i.toString()
                        )
                ),
            }));
        }
    };

    useEffect(() => {
        // console.log(OtherInfoDetails);
    }, [OtherInfoDetails, OtherInfoDetailsAddon]);

    // const handleOtherInfo = (event) => {
    //     const { name, value } = event.target;
    //     OtherInfo({ ...OtherInfoDetails, [name]: value });
    // };

    // console.log(OtherInfoDetails);

    const handleInput = (event) => {
        const { name, value } = event.target;
        setTimeout(() => {
            const currentValue = event.target.value;
            setCustomerDetails(prev => ({
                ...prev,
                [name]: currentValue
            }));
        }, 0);

       
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        setCustomerDetails(prev => ({
            ...prev,
            [name]: value
        }));

        // if (customerDetails.email != '' && validateEmail(customerDetails.email)) {
        //     var discountedAmountOG = parseFloat(currConvertOG(discountedAmount));
        //     var amountInAED = parseFloat(discountedAmountOG.toFixed(2));
        //     var amountInFils = Math.round(amountInAED * 100);
        //     props.updatePaymentIntent(amountInFils, props.clientSecretKey, props.bookingDetails.product_id, customerDetails);
        // }
    };

    const validateEmail = (email) => {
        // Basic email validation regex
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };


    useEffect(() => {
        console.log('Customer details updated:', customerDetails);

        if (customerDetails.email != '' && validateEmail(customerDetails.email) && customerDetails.actual_number.length > 7) {
            handleTimeout();
            if (customerDetails.email != '' && validateEmail(customerDetails.email)) {
                var discountedAmountOG = parseFloat(currConvertOG(discountedAmount));
                var amountInAED = parseFloat(discountedAmountOG.toFixed(2));
                var amountInFils = Math.round(amountInAED * 100);
                props.updatePaymentIntent(amountInFils, props.clientSecretKey, props.bookingDetails.product_id, customerDetails);
            }
        }

    }, [customerDetails]);

    const handleTimeout = async () => {
        try {
            fetch(`${mainApiUrl}/insert_temp_booking.php`, {
                method: "POST",
                body: JSON.stringify({
                    product_id: prodId,
                    email_id: customerDetails.email,
                    first_name: customerDetails.first_name,
                    last_name: customerDetails.last_name,
                    actual_number: customerDetails.actual_number,
                    country_code: customerDetails.country_code,
                    newIdentifier: identifier,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.ok) {
                    console.error("Temp updated");
                } else {
                    console.error("Temp update error");
                }
            }).catch((error) => {
                console.error("Temp update error:", error);
            });

            // console.log('API call response:', response.data);
        } catch (error) {
            // console.error('API call error:', error);
        }
    };
    const [isRequired, setIsRequired] = useState(false);
    const handleInput_phone = (value, country) => {
        const countryCode = country.dialCode;
        const actualNumber = value.replace(countryCode, "");
        setIsRequired(value.length > 5);

        if(actualNumber.length > 5){
            setCustomerDetails({
                ...customerDetails,
                country_code: country.dialCode,
                mobile_number: value,
                actual_number: actualNumber,
            });
        }
    };
    // const DateOfBirthForm = () => {
    //     const [dob, setDob] = useState(null);
    //     return (
    //           <DatePicker
    //             id="dob"
    //             className={`form-control`}
    //             dateFormat="yyyy-MM-dd"
    //             maxDate={new Date()}
    //             showYearDropdown
    //             showMonthDropdown
    //             scrollableYearDropdown
    //             yearDropdownItemNumber={100}
    //             required
    //           />
    //     );
    //   };
    useEffect(() => {
        var discountedAmountOG = parseFloat(currConvertOG(discountedAmount));
        var amountInAED = parseFloat(discountedAmountOG.toFixed(2));
        var amountInFils = Math.round(amountInAED * 100);
        props.updatePaymentIntent(amountInFils, props.clientSecretKey, props.bookingDetails.product_id, customerDetails);
    }, []);
    // console.log(props);

    return (
        <>
            {!isLoggedIn && (
                <>
                    <div className="checkout-link-box">
                        <p>
                            <i className="bi bi-person-circle"></i>{" "}
                            <span className="text-blue" onClick={openSigninModal}>
                                Create an account
                            </span>{" "}
                            or{" "}
                            <span className="text-blue" onClick={openLoginModal}>
                                Log in
                            </span>{" "}
                            for faster checkout
                        </p>
                    </div>
                    <p className="text-divider text-center">
                        <span>or continue as a Guest</span>
                    </p>
                </>
            )}
            <div className="checkout-form">
                <h3>Booking details</h3>

                <form
                    id="form-container"
                    onSubmit={(e) => handleFormSubmitCard(e, props.bookingDetails, props.bookingDetails_addon)}
                >
                    <div className="row">
                        <div className="col-lg-6 col-md-6 form-group">
                            <input
                                type="text"
                                name="first_name"
                                className="form-control"
                                maxLength="50"
                                id="first_name"
                                placeholder="First name *"
                                required
                                onChange={handleInput}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 form-group">
                            <input
                                type="text"
                                name="last_name"
                                className="form-control"
                                maxLength="50"
                                id="last_name"
                                placeholder="Last name *"
                                required
                                onChange={handleInput}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 form-group">
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email address *"
                                required
                                onChange={handleInput}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 form-group">
                            {
                                <PhoneInput
                                    placeholder="Mobile Number *"
                                    country={"ae"}
                                    onChange={(value, country) =>
                                        handleInput_phone(value, country)
                                    }
                                    inputProps={{
                                        required: isRequired // Making the input required
                                    }}
                                />
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 form-group">
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={promoCode}
                                    onChange={(e) => setPromoCode(e.target.value)}
                                    placeholder="Promo Code"
                                />
                                <button
                                    className="promocode"
                                    name="promocode"
                                    id="promocode"
                                    type="button"
                                    onClick={handleApplyPromoCode}
                                >
                                    Apply
                                </button>
                            </div>
                            {promoCodeValid === "success" ? (
                                <div className="text-success">
                                    Promo code applied successfully! After discount amount:{" "}
                                    {selectedCurrency} {(discountedAmount).toFixed(2)}
                                </div>
                            ) : promoCodeValid === "error" ? (
                                <div className="text-danger">
                                    Invalid promo code. Please try again.
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 form-group">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkout-check"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />{" "}
                            <label className="form-check-label" htmlFor="checkout-check">
                                {" "}
                                Add special requests
                            </label>
                        </div>
                    </div>

                    {isChecked && (
                        <div className="row">
                            <div className="col-xl-12 form-group">
                                <textarea
                                    className="form-control"
                                    name="request"
                                    id="request"
                                    rows="3"
                                    placeholder="Let us know if you have any special requests (e.g. dietary, accessibility, etc.)"
                                    onChange={handleInput}
                                ></textarea>
                            </div>
                        </div>
                    )}

                    {(Object.keys(proDatadob).length > 0 || Object.keys(travelers_name).length > 0 || Object.keys(proDatamob).length > 0 || proDataDoc.length > 0) && proinfotype == 1 ? (
                        <>
                            <h3>Traveler's Information</h3>
                            <div className="row">
                                {travelers_name.input_type === "Name" && (
                                    <>
                                        <div className="col-lg-6 col-md-6 form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={travelers_name.input_name}
                                                placeholder={travelers_name.input_placehoder}
                                                onChange={(e) =>
                                                    handleInputTravname(
                                                        travelers_name.input_name,
                                                        e.target.value,
                                                        null,
                                                        0
                                                    )
                                                }
                                                required={travelers_name.required === "required"}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-6 form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={travelers_name.input_lname}
                                                placeholder={travelers_name.input_lplacehoder}
                                                onChange={(e) =>
                                                    handleInputTravname(
                                                        travelers_name.input_lname,
                                                        e.target.value,
                                                        null,
                                                        0
                                                    )
                                                }
                                                required={travelers_name.required === "required"}
                                            />
                                        </div>
                                    </>
                                )}

                                {proDatamob.input_type === "Mobile Number" && (
                                    <div className="col-lg-6 col-md-6 form-group">
                                        <PhoneInput
                                            country={"ae"}
                                            placeholder="Mobile Number *"
                                            // required={proDatamob.required === "required"}
                                            required
                                            onChange={(value, country) =>
                                                handleInputTravmob(
                                                    proDatamob.input_name,
                                                    value,
                                                    country,
                                                    null,
                                                    0
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                {proDatadob.input_type === "Date of Birth" && (
                                    <div className="col-lg-6 col-md-6 form-group">
                                        <DatePicker
                                            id={`dob_${0}`}
                                            className="form-control"
                                            selected={selectedDate[`dob_${0}`]}
                                            onChange={(date) =>
                                                handleInputTravdob(
                                                    `dob_${0}`,
                                                    date,
                                                    null,
                                                    0,
                                                    proDatadob.input_name
                                                )
                                            }
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText={proDatadob.input_placehoder}
                                            maxDate={new Date()}
                                            showYearDropdown
                                            name={`dob_${0}`.input_name}
                                            showMonthDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            required={proDatadob.required === "required"}
                                        />
                                    </div>
                                )}

                                {proDataDoc.length > 0 && (
                                    <>
                                        {proDataDoc.map((item, index) => (
                                            <div key={index} className="col-lg-6 col-md-6 form-group">
                                                <input
                                                    type="file"
                                                    name={item.input_name}
                                                    className={`form-control ${item.class_name}`}
                                                    maxLength={item.max_length}
                                                    id={""}
                                                    accept=".pdf, .png, .jpeg, .jpg, .webp"
                                                    required={item.required === "required"}
                                                    onChange={(e) =>
                                                        handleInputTrav1(
                                                            item.input_name,
                                                            e.target.files,
                                                            "file",
                                                            null,
                                                            0, e
                                                        )
                                                    }
                                                />

                                                <small style={{ fontStyle: "italic" }}>
                                                    Please upload {item.input_placehoder}
                                                </small>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </>
                    ) : null}

                    {(Object.keys(proDatadobAddon).length > 0 || Object.keys(travelers_nameAddon).length > 0 || Object.keys(proDatamobAddon).length > 0 || proDataDocAddon.length > 0) && proinfotypeAddon == 1 ? (
                        <>
                            <h3>Traveler's Information (Addon)</h3>
                            <div className="row">
                                {travelers_nameAddon.input_type === "Name" && (
                                    <>
                                        <div className="col-lg-6 col-md-6 form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={travelers_nameAddon.input_name}
                                                placeholder={travelers_nameAddon.input_placehoder}
                                                onChange={(e) =>
                                                    handleInputTravnameAddon(
                                                        travelers_nameAddon.input_name,
                                                        e.target.value,
                                                        null,
                                                        0
                                                    )
                                                }
                                                required={travelers_nameAddon.required === "required"}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-6 form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={travelers_nameAddon.input_lname}
                                                placeholder={travelers_nameAddon.input_lplacehoder}
                                                onChange={(e) =>
                                                    handleInputTravnameAddon(
                                                        travelers_nameAddon.input_lname,
                                                        e.target.value,
                                                        null,
                                                        0
                                                    )
                                                }
                                                required={travelers_nameAddon.required === "required"}
                                            />
                                        </div>
                                    </>
                                )}

                                {proDatamobAddon.input_type === "Mobile Number" && (
                                    <div className="col-lg-6 col-md-6 form-group">
                                        <PhoneInput
                                            country={"ae"}
                                            placeholder="Mobile Number *"
                                            // required={proDatamobAddon.required === "required"}
                                            required
                                            onChange={(value, country) =>
                                                handleInputTravmobAddon(
                                                    proDatamobAddon.input_name,
                                                    value,
                                                    country,
                                                    null,
                                                    0
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                {proDatadobAddon.input_type === "Date of Birth" && (
                                    <div className="col-lg-6 col-md-6 form-group">
                                        <DatePicker
                                            id={`dob_Addon_${0}`}
                                            className="form-control"
                                            selected={selectedDate[`dob_Addon_${0}`]}
                                            onChange={(date) =>
                                                handleInputTravdobAddon(
                                                    `dob_Addon_${0}`,
                                                    date,
                                                    null,
                                                    0,
                                                    proDatadobAddon.input_name
                                                )
                                            }
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText={proDatadobAddon.input_placehoder}
                                            maxDate={new Date()}
                                            showYearDropdown
                                            name={`dob_Addon_${0}`.input_name}
                                            showMonthDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            required={proDatadobAddon.required === "required"}
                                        />
                                    </div>
                                )}

                                {proDataDocAddon.length > 0 && (
                                    <>
                                        {proDataDocAddon.map((item, index) => (
                                            <div key={index} className="col-lg-6 col-md-6 form-group">
                                                <input
                                                    type="file"
                                                    name={item.input_name}
                                                    className={`form-control ${item.class_name}`}
                                                    maxLength={item.max_length}
                                                    id={""}
                                                    accept=".pdf, .png, .jpeg, .jpg, .webp"
                                                    required={item.required === "required"}
                                                    onChange={(e) =>
                                                        handleInputTrav1Addon(
                                                            item.input_name,
                                                            e.target.files,
                                                            "file",
                                                            null,
                                                            0, e
                                                        )
                                                    }
                                                />

                                                <small style={{ fontStyle: "italic" }}>
                                                    Please upload {item.input_placehoder}
                                                </small>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </>
                    ) : null}

                    {(proinfotype == 2) ? (
                        <>
                            <h3>Traveler's Information</h3>
                            {props.bookingDetails?.selectedTickets.map(
                                (ticket_data, index) =>
                                    ticket_data.quantity > 0 && (
                                        <React.Fragment key={index}>
                                            {Array.from({ length: ticket_data.quantity }).map(
                                                (_, i) => (
                                                    <>
                                                        <h5>
                                                            {ticket_data.tick_ids_name} <small style={{ fontStyle: 'italic' }}>( passenger {i + 1} )</small>
                                                        </h5>
                                                        <div key={i} className="row">
                                                            {travelers_name.input_type === "Name" && (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name={travelers_name.input_name}
                                                                            placeholder={
                                                                                travelers_name.input_placehoder
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleInputTravname(
                                                                                    travelers_name.input_name,
                                                                                    e.target.value,
                                                                                    ticket_data.tick_ids_name,
                                                                                    `${i + 1}`
                                                                                )
                                                                            }
                                                                            required={travelers_name.required === "required"}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name={travelers_name.input_lname}
                                                                            placeholder={
                                                                                travelers_name.input_lplacehoder
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleInputTravname(
                                                                                    travelers_name.input_lname,
                                                                                    e.target.value,
                                                                                    ticket_data.tick_ids_name,
                                                                                    `${i + 1}`
                                                                                )
                                                                            }
                                                                            required={travelers_name.required === "required"}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                            {proDatamob.input_type === "Mobile Number" && (
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <PhoneInput
                                                                        country={"ae"}
                                                                        placeholder="Mobile Number *"
                                                                        // required={proDatamob.required === "required"}
                                                                        inputProps={{
                                                                            required: true // Making the input required
                                                                        }}
                                                                        required
                                                                        onChange={(value, country) => {
                                                                            // Valid phone number entered
                                                                            handleInputTravmob(
                                                                                proDatamob.input_name,
                                                                                value,
                                                                                country,
                                                                                ticket_data.tick_ids_name,
                                                                                `${i + 1}`
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                            {proDatadob.input_type === "Date of Birth" && (
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <DatePicker
                                                                        // id={`dob_${i + 1}`} // Unique identifier for DatePicker
                                                                        className="form-control"
                                                                        selected={
                                                                            selectedDate[
                                                                            `dob_${ticket_data.tick_ids_name}_${i + 1
                                                                            }`
                                                                            ]
                                                                        }
                                                                        onChange={(date) =>
                                                                            handleInputTravdob(
                                                                                `dob_${ticket_data.tick_ids_name}_${i + 1}`,
                                                                                date,
                                                                                ticket_data.tick_ids_name,
                                                                                `${i + 1}`,
                                                                                proDatadob.input_name
                                                                            )
                                                                        }
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText={proDatadob.input_placehoder}
                                                                        maxDate={new Date()}
                                                                        showYearDropdown
                                                                        name={`dob_${ticket_data.tick_ids_name}_${i + 1
                                                                            }`}
                                                                        showMonthDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={100}
                                                                        required={proDatadob.required === "required"}
                                                                    />
                                                                </div>
                                                            )}

                                                            {proDataDoc.length > 0 && (
                                                                <>
                                                                    {proDataDoc.map((item, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="col-lg-6 col-md-6 form-group"
                                                                        >
                                                                            <input
                                                                                type="file"
                                                                                name={item.input_name}
                                                                                className={`form-control ${item.class_name}`}
                                                                                maxLength={item.max_length}
                                                                                id={""}
                                                                                required={item.required === "required"}
                                                                                onChange={(e) =>
                                                                                    handleInputTrav1(
                                                                                        item.input_name,
                                                                                        e.target.files,
                                                                                        "file",
                                                                                        ticket_data.tick_ids_name,
                                                                                        `${i + 1}`, e
                                                                                    )
                                                                                }
                                                                                accept=".pdf, .png, .jpeg, .jpg, .webp"
                                                                            />
                                                                            <small style={{ fontStyle: "italic" }}>
                                                                                Please upload {item.input_placehoder}
                                                                            </small>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </React.Fragment>
                                    )
                            )}
                        </>
                    ) : null}

                    {(proinfotypeAddon == 2 && props.bookingDetails_addon && props.bookingDetails_addon.selectedTickets) ? (
                        <>
                            <h3>Traveler's Information (addon)</h3>
                            {props.bookingDetails_addon?.selectedTickets.map(
                                (ticket_data, index) =>
                                    ticket_data.quantity > 0 && (
                                        <React.Fragment key={index}>
                                            {Array.from({ length: ticket_data.quantity }).map(
                                                (_, i) => (
                                                    <>
                                                        <h5>
                                                            {ticket_data.tick_ids_name} <small style={{ fontStyle: 'italic' }}>( passenger {i + 1} )</small>
                                                        </h5>
                                                        <div key={i} className="row">
                                                            {travelers_nameAddon.input_type === "Name" && (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name={travelers_nameAddon.input_name}
                                                                            placeholder={
                                                                                travelers_nameAddon.input_placehoder
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleInputTravname(
                                                                                    travelers_nameAddon.input_name,
                                                                                    e.target.value,
                                                                                    ticket_data.tick_ids_name,
                                                                                    `${i + 1}`
                                                                                )
                                                                            }
                                                                            required={travelers_nameAddon.required === "required"}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name={travelers_nameAddon.input_lname}
                                                                            placeholder={
                                                                                travelers_nameAddon.input_lplacehoder
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleInputTravname(
                                                                                    travelers_nameAddon.input_lname,
                                                                                    e.target.value,
                                                                                    ticket_data.tick_ids_name,
                                                                                    `${i + 1}`
                                                                                )
                                                                            }
                                                                            required={travelers_nameAddon.required === "required"}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                            {proDatamobAddon.input_type === "Mobile Number" && (
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <PhoneInput
                                                                        country={"ae"}
                                                                        placeholder="Mobile Number *"
                                                                        // required={proDatamobAddon.required === "required"}
                                                                        inputProps={{
                                                                            required: true // Making the input required
                                                                        }}
                                                                        required
                                                                        onChange={(value, country) => {
                                                                            // Valid phone number entered
                                                                            handleInputTravmobAddon(
                                                                                proDatamobAddon.input_name,
                                                                                value,
                                                                                country,
                                                                                ticket_data.tick_ids_name,
                                                                                `${i + 1}`
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                            {proDatadobAddon.input_type === "Date of Birth" && (
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <DatePicker
                                                                        // id={`dob_Addon_${i + 1}`} // Unique identifier for DatePicker
                                                                        className="form-control"
                                                                        selected={
                                                                            selectedDate[
                                                                            `dob_Addon_${ticket_data.tick_ids_name}_${i + 1
                                                                            }`
                                                                            ]
                                                                        }
                                                                        onChange={(date) =>
                                                                            handleInputTravdobAddon(
                                                                                `dob_Addon_${ticket_data.tick_ids_name}_${i + 1}`,
                                                                                date,
                                                                                ticket_data.tick_ids_name,
                                                                                `${i + 1}`,
                                                                                proDatadobAddon.input_name
                                                                            )
                                                                        }
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText={proDatadobAddon.input_placehoder}
                                                                        maxDate={new Date()}
                                                                        showYearDropdown
                                                                        name={`dob_Addon_${ticket_data.tick_ids_name}_${i + 1
                                                                            }`}
                                                                        showMonthDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={100}
                                                                        required={proDatadobAddon.required === "required"}
                                                                    />
                                                                </div>
                                                            )}

                                                            {proDataDocAddon.length > 0 && (
                                                                <>
                                                                    {proDataDocAddon.map((item, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="col-lg-6 col-md-6 form-group"
                                                                        >
                                                                            <input
                                                                                type="file"
                                                                                name={item.input_name}
                                                                                className={`form-control ${item.class_name}`}
                                                                                maxLength={item.max_length}
                                                                                id={""}
                                                                                required={item.required === "required"}
                                                                                onChange={(e) =>
                                                                                    handleInputTrav1Addon(
                                                                                        item.input_name,
                                                                                        e.target.files,
                                                                                        "file",
                                                                                        ticket_data.tick_ids_name,
                                                                                        `${i + 1}`, e
                                                                                    )
                                                                                }
                                                                                accept=".pdf, .png, .jpeg, .jpg, .webp"
                                                                            />
                                                                            <small style={{ fontStyle: "italic" }}>
                                                                                Please upload {item.input_placehoder}
                                                                            </small>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </React.Fragment>
                                    )
                            )}
                        </>
                    ) : null}



                    {(Object.keys(prootherinfoArr).length > 0) && proinfotype == 1 ? (
                        <>
                            <h3>Other information</h3>
                            <div className="row">
                                {prootherinfoArr.map((item, index) => (
                                    <div key={index} className="col-lg-6 col-md-6 form-group">
                                        <input
                                            type="text"
                                            name={item.input_name}
                                            className={`form-control ${item.class_name}`}
                                            maxLength={item.max_length}
                                            id={""}
                                            placeholder={item.input_placehoder}
                                            required={item.required === "required"}
                                            onChange={(e) =>
                                                handleOtherInfo(
                                                    item.input_name,
                                                    e.target.value,
                                                    null, 0
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}

                    {(Object.keys(prootherinfoArrAddon).length > 0) && proinfotypeAddon == 1 ? (
                        <>
                            <h3>Other information (Addon)</h3>
                            <div className="row">
                                {prootherinfoArrAddon.map((item, index) => (
                                    <div key={index} className="col-lg-6 col-md-6 form-group">
                                        <input
                                            type="text"
                                            name={item.input_name}
                                            className={`form-control ${item.class_name}`}
                                            maxLength={item.max_length}
                                            id={""}
                                            placeholder={item.input_placehoder}
                                            required={item.required === "required"}
                                            onChange={(e) =>
                                                handleOtherInfoAddon(
                                                    item.input_name,
                                                    e.target.value,
                                                    null, 0
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}

                    {(Object.keys(prootherinfoArr).length > 0) && proinfotype == 2 ? (

                        <>
                            <h3>Other information</h3>
                            {props.bookingDetails?.selectedTickets.map((ticket_data, ticketIndex) =>
                                ticket_data.quantity > 0 && (
                                    <React.Fragment key={ticketIndex}>
                                        {Array.from({ length: ticket_data.quantity }).map((_, quantityIndex) => (
                                            <React.Fragment key={`${ticketIndex}-${quantityIndex}`}>
                                                <h5>
                                                    {ticket_data.tick_ids_name} {quantityIndex + 1}
                                                </h5>
                                                <div className="row">
                                                    {prootherinfoArr.length > 0 && (
                                                        <>
                                                            {prootherinfoArr.map((item, itemIndex) => (
                                                                <div
                                                                    key={`${ticketIndex}-${quantityIndex}-${itemIndex}`}
                                                                    className="col-lg-6 col-md-6 form-group"
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        name={item.input_name}
                                                                        className={`form-control ${item.class_name}`}
                                                                        maxLength={item.max_length}
                                                                        placeholder={item.input_placehoder}
                                                                        required={item.required === "required"}
                                                                        onChange={(e) =>
                                                                            handleOtherInfo(
                                                                                item.input_name,
                                                                                e.target.value,
                                                                                ticket_data.tick_ids_name,
                                                                                `${quantityIndex + 1}`
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                )
                            )}
                        </>
                    ) : null}

                    {(Object.keys(prootherinfoArrAddon).length > 0) && proinfotypeAddon == 2 ? (
                        <>
                            <h3>Other information (Addon)</h3>
                            {props.bookingDetails_addon?.selectedTickets.map((ticket_data, ticketIndex) =>
                                ticket_data.quantity > 0 && (
                                    <React.Fragment key={ticketIndex}>
                                        {Array.from({ length: ticket_data.quantity }).map((_, quantityIndex) => (
                                            <React.Fragment key={`${ticketIndex}-${quantityIndex}`}>
                                                <h5>
                                                    {ticket_data.tick_ids_name} {quantityIndex + 1}
                                                </h5>
                                                <div className="row">
                                                    {prootherinfoArrAddon.length > 0 && (
                                                        <>
                                                            {prootherinfoArrAddon.map((item, itemIndex) => (
                                                                <div
                                                                    key={`${ticketIndex}-${quantityIndex}-${itemIndex}`}
                                                                    className="col-lg-6 col-md-6 form-group"
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        name={item.input_name}
                                                                        className={`form-control ${item.class_name}`}
                                                                        maxLength={item.max_length}
                                                                        placeholder={item.input_placehoder}
                                                                        required={item.required === "required"}
                                                                        onChange={(e) =>
                                                                            handleOtherInfoAddon(
                                                                                item.input_name,
                                                                                e.target.value,
                                                                                ticket_data.tick_ids_name,
                                                                                `${quantityIndex + 1}`
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                )
                            )}
                        </>
                    ) : null}


                    <h3>Payment </h3>
                    <div className="accordion">
                        <div className="accordion-item">
                            <div className="row">
                                <div
                                    className="col-xl-12 form-group"
                                    style={{ paddingBottom: 0 + "em" }}
                                >
                                    <input
                                        type="radio"
                                        id="option-1"
                                        value="option-1"
                                        checked={selectedOption === "option-1"}
                                        onChange={handleOptionChange}
                                        className="form-check-input pr-2"
                                    />
                                    <label htmlFor="option-1" className="accordion-header">
                                        {" "}
                                        &nbsp;&nbsp;Card Payment
                                    </label>
                                </div>
                            </div>

                            <div
                                className={`accordion-collapse collapse${selectedOption === "option-1" ? " show" : ""
                                    }`}
                            >
                                <div className="accordion-body " style={{ padding: 0 + "em" }}>
                                    <span>
                                        <div className="row pb-3">
                                            <div className="col-xl-12 form-group pt-3">
                                                {/* <TapPayment></TapPayment> */}
                                                <StripePayment />

                                            </div>
                                        </div>
                                    </span>
                                    {/* <PaymentElement id="payment-element" options={paymentElementOptions} /> */}
                                </div>
                            </div>
                        </div>
                        {isSafari === true && (
                            <div className="accordion-item">
                                <div className="row">
                                    <div
                                        className="col-xl-12 form-group"
                                        style={{ paddingBottom: 0 + "em" }}
                                    >
                                        <input
                                            type="radio"
                                            id="option-2"
                                            value="option-2"
                                            checked={selectedOption === "option-2"}
                                            onChange={handleOptionChange}
                                            className="form-check-input pr-2"
                                        />
                                        <label
                                            htmlFor="option-2"
                                            className="accordion-header form-check-label"
                                        >
                                            {" "}
                                            &nbsp;&nbsp;Apple Pay
                                        </label>
                                    </div>
                                </div>
                                <div
                                    className={`accordion-collapse collapse${selectedOption === "option-2" ? " show" : ""
                                        }`}
                                >
                                    <div
                                        className="accordion-body"
                                        style={{ padding: 0 + "em" }}
                                    ></div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="row checkout-fixed">
                        <div className="d-flex justify-content-between align-items-center">
                            {showMobileComponent && (
                                <div>
                                    <span>Total</span>
                                    <div className="price">
                                        {selectedCurrency}{" "}
                                        {props.discountPercent > 0
                                            ? (discountedAmount + discountedAmountAddon)
                                            : currConvert((props.bookingDetails?.totalAmount + addonTotalAmount))}
                                    </div>
                                </div>
                            )}
                            <TapPayment></TapPayment>

                            <div className="text-left">
                                {/* {console.log("loading...", loading)} */}
                                {loading ? (
                                    <PaymentLoader /> // Display loader when loading is true
                                ) : (
                                    applePayOk === 0 && (
                                        <Button type="submit" variant="primary">
                                            Continue to payment
                                        </Button>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="checkout-payicon">
                <h3>Payment methods</h3>
                <div className="">
                    <img
                        src={visa}
                        className="img-fluid"
                        alt="visa"
                        loading="lazy"
                        width="100%"
                        height="auto"
                    />
                    <img
                        src={mastercard}
                        className="img-fluid"
                        alt="mastercard"
                        loading="lazy"
                        width="100%"
                        height="auto"
                    />
                    <img
                        src={gpay}
                        className="img-fluid"
                        alt="gpay"
                        loading=" lazy"
                        width="100%"
                        height="auto"
                    />
                    <img
                        src={applepay}
                        className="img-fluid"
                        alt="applepay"
                        loading="lazy"
                        width="100%"
                        height="auto"
                    />
                    <img
                        src={americanexpress}
                        className="img-fluid"
                        alt="americanexpress"
                        loading="lazy"
                        width="100%"
                        height="auto"
                    />
                    <img
                        src={unionpay}
                        className="img-fluid"
                        alt="unionpay"
                        loading="lazy"
                        width="100%"
                        height="auto"
                    />
                </div>
            </div>

            <SignupModal
                showSignup={showSignupModal}
                onHide={closeSignupModal}
                handleSuccessfulLogin={handleSuccessfulLogin}
                setSuccessfulGoogleLogin={setSuccessfulGoogleLogin}
                openLoginModal={openLoginModal}
                openSigninModal={openSigninModal}
                handleGoogleLoginSuccess={handleGoogleLoginSuccess}
            />

            <LoginModal
                showLogin={showLoginModal}
                onHide={closeLoginModal}
                handleSuccessfulLogin={handleSuccessfulLogin}
                setSuccessfulGoogleLogin={setSuccessfulGoogleLogin}
                openSignupModal={openSignupModal}
                handleGoogleLoginSuccess={handleGoogleLoginSuccess}
            />

            <SigninModal
                showSignin={showSigninModal}
                onHide={closeSigninModal}
                openLoginModal={openLoginModal}
            />
        </>
    );
};
